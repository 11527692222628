/* Loader */
.loader-container {
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    text-align: center;
    opacity: 0.7;
    background-color: #e9ecefbd;
    z-index: 99999999999;
}
#loader {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 99999999999;
    width: 100px;
    height: 100px;
    margin: -76px 0 0 -76px;
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


/* Add animation to "page content" */

.animate-bottom {
    position: relative;
    -webkit-animation-name: animatebottom;
    -webkit-animation-duration: 1s;
    animation-name: animatebottom;
    animation-duration: 1s
}

@-webkit-keyframes animatebottom {
    from {
        bottom: -100px;
        opacity: 0
    }
    to {
        bottom: 0px;
        opacity: 1
    }
}

@keyframes animatebottom {
    from {
        bottom: -100px;
        opacity: 0
    }
    to {
        bottom: 0;
        opacity: 1
    }
}


/* End Loader */
.required:after {
    content:" *";
    color: red;
  }
  .intl-tel-input{
      width: 100%;
  }
  input[placeholder], [placeholder], *[placeholder] {
    color: #212529;
}
.form-control.bg-r:disabled, .bg-r.form-control[readonly] {
    background-color: #ffffff;
    opacity: 1;
    border: none;
    font-size: 20px;
    height: 20px;
}
.uploadProfileInput{
    display: none;
}
.profile-pic-wrapper {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .pic-holder {
    text-align: center;
    position: relative;
    border-radius: 50%;
    width: 150px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .pic-holder .pic {
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
  }
  
  .pic-holder .upload-file-block,
  .pic-holder .upload-loader {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(90, 92, 105, 0.7);
    color: #f8f9fc;
    font-size: 12px;
    font-weight: 600;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s;
  }
  
  .pic-holder .upload-file-block {
    cursor: pointer;
  }
  
  .pic-holder:hover .upload-file-block {
    opacity: 1;
  }
  
  .pic-holder.uploadInProgress .upload-file-block {
    display: none;
  }
  
  .pic-holder.uploadInProgress .upload-loader {
    opacity: 1;
  }
  
  /* Snackbar css */
  .snackbar {
    visibility: hidden;
    min-width: 250px;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 2px;
    padding: 16px;
    position: fixed;
    z-index: 1;
    left: 50%;
    bottom: 30px;
    font-size: 14px;
    transform: translateX(-50%);
  }
  
  .snackbar.show {
    visibility: visible;
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
  }
  
  @-webkit-keyframes fadein {
    from {
      bottom: 0;
      opacity: 0;
    }
    to {
      bottom: 30px;
      opacity: 1;
    }
  }
  
  @keyframes fadein {
    from {
      bottom: 0;
      opacity: 0;
    }
    to {
      bottom: 30px;
      opacity: 1;
    }
  }
  
  @-webkit-keyframes fadeout {
    from {
      bottom: 30px;
      opacity: 1;
    }
    to {
      bottom: 0;
      opacity: 0;
    }
  }
  
  @keyframes fadeout {
    from {
      bottom: 30px;
      opacity: 1;
    }
    to {
      bottom: 0;
      opacity: 0;
    }
  }
  
  .container-update-prof {
    width: 100%;
    height: 100vh;
    background: #f0f0f05e;
}
.content-update-prof{
  left: 23%;
  right: 23%;
  position: absolute;
}
.update-prof_tab {
  width: 100%;
  display: flex;
  padding: 21px;
  /* border-bottom: 1px solid #f3f2f8;
  box-shadow: 0 6px 9px 0 rgb(0 0 0 / 20%); */
  background: white;
  border-top: 1px dotted #8080806e;
}
.content-update-prof .tab-content {
  padding: 0 50px 0 50px;
  /* border-radius: 11px; */
  background: white;
  margin: 15px 0;
}
.up-nav-prof {
  width: 100%;
}
.update-prof_tab li.nav-item {
  width: 20%;
  text-align: center;
  font-weight: 600;
}
.update-prof_tab li.nav-item a.active {
  border-bottom: 2px solid #400cca;
  padding: 0 0 20px;
}
.up-prof-footer {
  display: grid;
  grid-template-columns: 1fr 18fr 1fr;
  grid-gap: 10px;
}

.ict-profile-screen-left-triangle {
  -webkit-transform: translateY(-20px) translateX(25px) rotate(
237deg);
  left: -61px;
}
.ict-profile-screen-left-triangle, .ict-profile-screen-right-triangle {
  width: 112px;
  height: 47px;
  position: absolute;
  top: 53px;
  border-radius: -127px;
  background-color: #f4f5f6;
}
.ict-profile-screen-right-triangle {
  -webkit-transform: translateY(-20px) translateX(25px) rotate(
-237deg);
  right: -17px;
}
.ict-profile-screen-left-triangle, .ict-profile-screen-right-triangle {
  width: 112px;
  height: 47px;
  position: absolute;
  top: 53px;
  border-radius: -127px;
  background-color: #f4f5f6;
}
.thank-you-bg {
  height: 100vh;
  position: relative;
}
.thank-u-container {
  height: 100vh;
  background-size: 100% 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.thank-u-content {
  text-align: center;
  color: white;
}
.resend-otp-thank-u {
  color: #f8f9fa;
  font-size: 20px;
  font-style: italic;
  cursor: pointer;
}
.v-otp-c {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.cursor-pointer{
  cursor: pointer;
}
.faqs button.accordion-button {
  width: 100%;
  font-size: 16px;
  font-weight: 700;
  text-align: justify;
  padding: 10px;
  line-height: initial;
  margin: 0;
  border-top: 1px solid #5a429b;
  border-radius: 20px;
  border-right: 1px solid #5a429b;
  border-left: 1px solid #5a429b;
}
.faqs .accordion-collapse.collapse.show {
  border-bottom: 1px solid #5a429b73;
  border-left: 1px solid #5a429b73;
  border-right: 1px solid #5a429b73;
  border-radius: 0 0 20px 20px;
  padding: 20px 10px 10px 10px;
    margin-top: -25px;
}
.inp-group{
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}
.inp-group-append{
  background-color: #ffc107;
  cursor: pointer;
}
label.rao-file-label {
  font-size: 14px;
  position: relative;
  width: 100%;
  padding: 10px;
  background: #5a429b;
  border-radius: 0px 0 10px 10px;
  color: white;
  margin: 0;
}

input.rao-file-input.inputfile {
    position: relative;
    width: 100%;
    display: none;
}
.edit-cart{
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 11px;
  right: 11px;
  z-index: 1;
}
/* ------------------- */
.pro-list .shop-item {
  display: grid;
  grid-template-rows: 1fr 0fr;
}
.pro-list img.mx-auto {
  max-height: 150px;
  width: 100%;
  height: 100%;
  min-height: 150px;
}
.image-gallery-thumbnails-container {
  border: 1px solid #dee2e6;
  border-radius: 10px;
  background: white;
}
.shop-item h4 a {
  color: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.grid-view-cart {
  display: grid;
  grid-template-columns: 9fr 3fr;
  grid-gap: 10px;
}
.view-cart-items label {
  font-size: 20px;
  font-weight: 500;
  text-align: left;
}

.view-cart-items span {
  font-size: 18px;
  text-align: end;
}

.view-cart-items {
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 10px 0 10px;
}
.view-cart-items.total label,.view-cart-items.total span {
  font-size: 22px;
  margin: 0;
  font-weight: 600;
  font-family: inherit;
}

.view-cart-items.total {
  border-top: 1px dashed;
  border-bottom: 1px dashed;
  padding: 10px 0 10px;
}
.v-c-items .image {
  height: auto;
  vertical-align: middle;
  max-height: 100px;
}

.row.v-c-items {
  height: 130px;
  margin: 0;
  border-bottom: 1px dashed #d8d8d8;
  padding: 15px 0 15px;
}
.text-link {
  color: #1776bb;
}
.product-quantity .form-control[readonly] {
  background-color: #ffffff !important;
  opacity: 1;
}
.inactive{
  color: #afb0aa !important;
}
.bg-grey {
  background: #f3f3f3 !important;
}
.favourites {
  color: red;
  border: none;
  font-size: 25px;
}
.content-scroll {
  max-height: 300px;
  scroll-behavior: smooth;
  animation-delay: inherit;
  overflow-y: scroll;
}
.modal-backdrop.show{
  z-index: 9 !important;
}


.prof-container {
  display: flex;
  max-width: 200px;
}

.prof-container img.prof-img {
  width: 35px;
  max-height: 35px;
}

.prof-container .prof-content {
  padding: 0 3px;
  display: grid;
  text-align: initial;
}
.prof-content h5.title {
  text-align: initial;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  padding: 0;
  line-height: initial;
  max-width: 50;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.prof-content a.sub-title {
  font-size: 12px;
    cursor: pointer;
    color: #007bff;
    float: left;
}
.cate-search-list.searching-dropdown .nav > li > ul {
  left: auto;
  padding-top: 5px  ;
  min-width: 100%;
  background: #e1e6ea;
  }
.cate-search-list {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
  flex-wrap: initial;
  overflow-x: scroll;
  min-height: 82vh;
}
.modal-dialog.modal-lg.cate-search {
  /* min-width: -webkit-fill-available; */
}
.sub-cate-search-list{
  /* background: #f8f9fa; */
  border-top: 1px solid #484fa3;
  list-style: none;
}
.cate-search-container {
  /* border-right: 1px solid gray; */
  list-style: none;
    width: max-content;
    margin-right: 5px;
    flex: 0 0 15%;
}
a.main-a {
  font-size: 18px;
  text-align: center;
  /* width: 100ch; */
  display: grid;
  height: 90px;
}
.cate-search-container:nth-child(odd) {
  box-shadow: 0px 2px 10px #888888;
}
.main-a img {
  width: 64px;
  height: 60px;
  border-radius: 50%;
}
.cate-search-content {
  font-size: 14px;
    display: grid;
    grid-template-columns: 3fr 9fr;
    grid-gap: 2px;
    /* min-width: 140px; */
    /* width: 100%; */
    /* max-width: 150px; */
    /* border-bottom: 1px solid #e0e5e9; */
    padding: 8px;
    height: 50px;
}
  .cate-search-content img {
    width: 50px;
    max-height: 50px;
    border-radius: 50%;
    height: auto;
    border: 1px solid white;
}
.cate-search-content label {
  margin: 0;
  line-height: initial;
}
.system-color {
  color: #ED4E94;
}
.table_responsive_scrool {
  overflow-x: auto;
}
.card-collapse .btn-link {
  padding: 0;
  width: 100%;
  float: left;
  text-align: inherit;
}

.card-collapse {
  padding: 10px;
  box-shadow: 3px 3px 6px 1px rgb(0 0 0 / 50%);
}
h4.ti-tle {
  margin: 0;
  padding: 1px;
  font-size: 20px;
  text-align: center;
}
.form-input-select {display: grid;grid-template-columns: 2fr 1fr;border: 1px solid #ced4da;border-radius: 3px;}

.form-input-select input.form-control {
    /* border-right: none; */
    border: none;
}

.form-input-select select.form-control {
    border: none;
    border-left: 1px solid #ced4da;
    box-shadow: 1px 1px 1px 0px #888888;
}
.cate-search-list::-webkit-scrollbar {
  display: none;
}
.active.cate-search-content {
  background: #2868b3;
  color: white;
}
a.cate-search-content {
  text-align: left;
  vertical-align: middle;
  display: table-cell;
  vertical-align: middle;
}
.col-form-label-ict label.form-check-label {
  margin: 0;
}
.col-form-label-ict .form-check{
  margin: 0;
}
.col-form-label-ict label.form-check-label {
  margin: 0 0 0 20px;
}
.col-form-label-ict .form-check-input{
  margin-left: 0;
}
.csc-122 .shop-heading .btn-link{
  margin-right: -20px;
}
.csc-item {
  text-align: center;
  font-size: 18px;
  border-radius: 8px;
  box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.csc-item:hover{
  background: linear-gradient(
    128deg, rgb(23 118 187 / 69%) 0%, rgb(58 90 170 / 84%) 50%, rgb(90 66 155 / 88%) 100%);
  color: #f8f9fa;
  opacity: 0.9;
}
.cate-search-carousel .owl-carousel .owl-item img {
  display: block;
  width: 29%;
  margin-left: 36%;
  max-width: 80px;
  max-height: 60px;
  min-height: 60px;
  border-radius: 50%;
}
span.carousel-item-txt {
  position: absolute;
  color: red;
  left: 6%;
  top: 40%;
}
.banenr-section .carousel-caption{
  top: 80%;
}
.img-h-001 {
  height: 80px;
  position: relative;
  vertical-align: middle;
}

.img-h-001 img {
  height: auto;
}

.csc-item label{
  overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 215px;
}
.dropdown-menu .mcsc
{
  position: absolute;
  left: 100%;
  width: 888px;
  border-left: 1px solid #eee;
  padding: 10px 0;
  z-index: 99;
  min-height: 480px;
  display: none;
  margin: 0;
  list-style: none;
  flex-wrap: wrap;
  transition: all;
  min-height: -webkit-fill-available;
  margin-top: -25px;
  background: #ececec;
  top:25px;
}

.mcsc .mcsc-iteam {
    width: 32.33%;
    /* padding: 10px 20px; */
    display: inline-block;
    float: left;
}
button.rounded-circle.gmail {
  border: none !important;
  background: none !important;
  box-shadow: none !important;
}
button.social-icon-login.metro {
  border: none;
  background: none;
  padding: 0;
}
.input_login .eye_icon {
  padding-right: 1;
  position: absolute;
  top: 12%;
  height: 75%;
  line-height: 36px;
  right: 0px;
  padding-right: 8px;
}
img.image-gallery-thumbnail-image {
  height: 50px;
}
/** Start loader*/
#dots2 {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 50px;
  width: 50px;
  margin: -25px 0 0 -25px;
}

#dots2 span {
  position: absolute;
  width: 10px;
  height: 10px;
  background: rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  -webkit-animation: dots2 1s infinite ease-in-out;
          animation: dots2 1s infinite ease-in-out;
}

#dots2 span:nth-child(1) {
  left: 0px;
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}

#dots2 span:nth-child(2) {
  left: 15px;
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}

#dots2 span:nth-child(3) {
  left: 30px;
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}

#dots2 span:nth-child(4) {
  left: 45px;
  -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s;
}

@keyframes dots2 {
  0% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px);
    -webkit-transform: translateX(5px);
            transform: translateX(5px);
    background: #000000;
  }
  50% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px);
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px);
    background: #000000;
  }
  100% {
    -webkit-transform: translateX(7px);
            transform: translateX(7px);
    -webkit-transform: translateX(7px);
            transform: translateX(7px);
    background: #000000;
  }
}
@-webkit-keyframes dots2 {
  0% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px);
    background: #000000;
  }
  50% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px);
    background: #000000;
  }
  100% {
    -webkit-transform: translateX(7px);
            transform: translateX(7px);
    background: #000000;
  }
}
/** End loader*/
.brand-doc-download .brand_value {
  display: flex;
}