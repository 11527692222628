.header__toggle {
    font-size: 1.4rem;
    position: absolute;
    right: -40px;
    z-index: 17;
    background: #5a429b;
    width: 40px;
    height: 40px;
    top: 50%;
    text-align: center;
    border-radius: 0 5px 5px 0;
}

#header-toggle{display: block;    line-height: 40px;
    width: 100%;
height: 100%;}
.bx-x:before {
    content: "\f00d"!important;
}

.header__toggle {
  color: #fff;
  cursor: pointer;
}

/*========== NAV ==========*/



/* For Google Chrome and others */
.nav__container::-webkit-scrollbar {
  display: none;
}

.nav__logo {
  font-weight: var(--font-semi-bold);
  margin-bottom: 2.5rem;
}

.nav__list, 
.nav__items {
  display: grid;
}

.nav__list {
  row-gap: 2.5rem;
}

.nav__items {
  row-gap: 1.5rem;
}

.nav__subtitle {
  font-size: var(--normal-font-size);
  text-transform: uppercase;
  letter-spacing: .1rem;
  color: var(--text-color-light);
}

.nav__link {
  display: flex;
  align-items: center;
  color: var(--text-color);
}

.nav__link:hover {
  color: var(--first-color);
}

.nav__icon {
  font-size: 1.2rem;
  margin-right: .5rem;
}

.nav__name {
  font-size: var(--small-font-size);
  font-weight: var(--font-medium);
  white-space: nowrap;
}

.nav__logout {
  margin-top: 5rem;
}

/* Dropdown */
.nav__dropdown {
  overflow: hidden;
  max-height: 21px;
  transition: .4s ease-in-out;
}

.nav__dropdown-collapse {
  background-color: var(--first-color-light);
  border-radius: .25rem;
  margin-top: 1rem;
}

.nav__dropdown-content {
  display: grid;
  row-gap: .5rem;    color: #c3b6e4;
  padding: .75rem 2.5rem .75rem 1.8rem;
}

.nav__dropdown-item {
position:relative;     color: #c3b6e4;
}
.nav__dropdown-item:after {
    content: "";
    position: absolute;
    left: -20px;
    top: 6px;
    background: #c3b6e4;
    width: 8px;
    height: 8px;
    border-radius: 100%;
}


.nav__dropdown-icon {
  margin-left: auto;
  transition: .4s;
}

/* Show dropdown collapse */
.nav__dropdown:hover {
  max-height: 100rem;
}

/* Rotate icon arrow */
.nav__dropdown:hover .nav__dropdown-icon:before {
 content: "\f068";
}

/*===== Show menu =====*/
.nav.show-menu {
  left: 0!important;
}

/*===== Active link =====*/
.active {
  color: var(--first-color);
}

.nav__container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 3rem;
  overflow: auto;
  scrollbar-width: none; /* For mozilla */
}

@media screen and (max-width: 991px) {
#navbar{
  top: 230px; width:200px;
  left: -200px;
  height: 100vh; 
  padding: 1rem 1rem 0;
  background-color: #5a429b;
  box-shadow: 1px 0 0 rgba(22, 8, 43, 0.1);
  z-index: 9;    position: relative;
  transition: .4s;color: #fff;
}
#navbar.navbar-scroll{ position:fixed; top:0px;    -webkit-transition: -webkit-transform .5s;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
    -webkit-transform: translateY(95px);    transform: translateY(95px);}

#navbar{
    position: fixed;
    top: 0;    z-index: 999;

}	

.navbar-scroll {

    -webkit-transform: translateY(0px);
    transform: translateY(0px);
}
	
}
@media screen and (min-width: 991px) {
	#navbar .nav__container{

  top: 230px; width:200px;
  left: -200px;
  height: 100vh; 
  padding: 1rem 1rem 0;
  background-color: #5a429b;
  box-shadow: 1px 0 0 rgba(22, 8, 43, 0.1);
  z-index: 9;    position: relative;
  transition: .4s;color: #fff;
}
#navbar.navbar-scroll .nav__container{ position:fixed; top:0px;    -webkit-transition: -webkit-transform .5s;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
    -webkit-transform: translateY(95px);    transform: translateY(95px);}

#navbar {    transition: .4s;
    min-width: 60px;
    margin-right: 20px;
    display: block;
    flex-wrap: initial;
}
#navbar:hover{     min-width: 219px;}
#navbar .nav__container {
    left: 0;
    padding: 1.2rem 1.5rem 0;
    width: 68px;

    top: -25px;
}

  .nav__items {
    row-gap: 1.7rem;
  }
  .nav__icon {
    font-size: 1.3rem;
  }

  /* Element opacity */
  .nav__logo-name, 
  .nav__name, 
  .nav__subtitle, 
  .nav__dropdown-icon {
    opacity: 0;
    transition: .3s;
  }
  
  
  /* Navbar expanded */
#navbar:hover .nav__container{
    width: 219px;
  }
  
  /* Visible elements */
#navbar:hover .nav__logo-name {
    opacity: 1;
  }
#navbar:hover .nav__subtitle {
    opacity: 1;
  }
#navbar:hover .nav__name {
    opacity: 1;
  }
#navbar:hover .nav__dropdown-icon {
    opacity: 1;
  }
}
