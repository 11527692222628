@font-face {
    font-family: 'aileronsemibold';
    src: url('../fonts/aileron-semibold-webfont.eot');
    src: url('../fonts/aileron-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/aileron-semibold-webfont.woff2') format('woff2'),
         url('../fonts/aileron-semibold-webfont.woff') format('woff'),
         url('../fonts/aileron-semibold-webfont.svg#aileronsemibold') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'aileronthin';
    src: url('../fonts/aileron-thin-webfont.eot');
    src: url('../fonts/aileron-thin-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/aileron-thin-webfont.woff2') format('woff2'),
         url('../fonts/aileron-thin-webfont.woff') format('woff'),
         url('../fonts/aileron-thin-webfont.svg#aileronthin') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'aileronblack';
    src: url('../fonts/aileron-black-webfont.eot');
    src: url('../fonts/aileron-black-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/aileron-black-webfont.woff2') format('woff2'),
         url('../fonts/aileron-black-webfont.woff') format('woff'),
         url('../fonts/aileron-black-webfont.svg#aileronblack') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'aileronbold';
    src: url('../fonts/aileron-bold-webfont.eot');
    src: url('../fonts/aileron-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/aileron-bold-webfont.woff2') format('woff2'),
         url('../fonts/aileron-bold-webfont.woff') format('woff'),
         url('../fonts/aileron-bold-webfont.svg#aileronbold') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'aileronlight';
    src: url('../fonts/aileron-light-webfont.eot');
    src: url('../fonts/aileron-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/aileron-light-webfont.woff2') format('woff2'),
         url('../fonts/aileron-light-webfont.woff') format('woff'),
         url('../fonts/aileron-light-webfont.svg#aileronlight') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'aileronregular';
    src: url('../fonts/aileron-regular-webfont.eot');
    src: url('../fonts/aileron-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/aileron-regular-webfont.woff2') format('woff2'),
         url('../fonts/aileron-regular-webfont.woff') format('woff'),
         url('../fonts/aileron-regular-webfont.svg#aileronregular') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'Century Gothic';
    src: url('../fonts/CenturyGothic.eot');
    src: url('../fonts/CenturyGothic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/CenturyGothic.woff2') format('woff2'),
        url('../fonts/CenturyGothic.woff') format('woff'),
        url('../fonts/CenturyGothic.ttf') format('truetype'),
        url('../fonts/CenturyGothic.svg#CenturyGothic') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


body {
	overflow-x: hidden;
	clear: both;
	color: #222;
	font-family: 'aileronregular';
	font-weight: 400;
	font-size: 16px;
	line-height: 1.42857143;
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased
}

* {
	outline: none
}

*:focus {
	outline: none
}

a {
	color: #000;
	transition: all ease .4s;
	text-decoration: none
}

a:focus {
	outline: none;
	outline-offset: 0
}

a:hover {
	color: #ff6363;
	text-decoration: none
}

a:focus {
	color: inherit
}
ul{ margin:0; padding:0}
img {
	max-width: 100%
}

iframe {
	display: inline-block;
	vertical-align: middle;
	width: 100%;
	border: none
}

h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6 {
	padding-bottom: 10px;
	margin-bottom: 0;
	font-weight: 600;
	line-height: 1.3
}

p {
	padding-bottom: 10px;
	margin-bottom: 0;
	    line-height: 25px;
	font-size: 15px;
	
}

p a {
	color: inherit
}

p a:hover {
	color: #222;
	text-decoration: underline
}

video {
	display: block;
	width: auto;
	min-width: 100%;
	height: auto;
	min-height: 100%;
	background: #f8f8f8
}

audio,
canvas,
progress,
video {
	vertical-align: middle
}

button {
	outline: none;
	outline-offset: 0
}

button:focus {
	outline: none;
	outline-offset: 0
}

figure {
	margin: 0
}

::selection,
::-webkit-selection,
::-moz-selection,
::-o-selection,
::-ms-selection {
	background: #ff6363;
	color: #fff
}

label {
	vertical-align: middle;
	cursor: pointer
}

.form-group {
	position: relative;
	margin-bottom: 1rem
}

..newsletter-box .form-control {
	height: 72px;
	font-size: 20px;
	font-weight: 400;
	box-shadow: none!important;
	border: none;
	border-radius: 5px;
	color: #838383;
	padding: 10px 25px
}

h1,
.h1 {
	font-size: 80px;

	
	font-weight: 700;
	
}

h2,
.h2 {
	font-size: 43px;
	line-height: 56px;
	color: #000;
	
}

input[placeholder],
[placeholder],
*[placeholder] {
	color: #CFCECE;
}

::-webkit-input-placeholder {
	color: #CFCECE;
	opacity: 1
}

::-moz-placeholder {
	color: #CFCECE;
	opacity: 1
}

:-ms-input-placeholder {
	color: #CFCECE;
	opacity: 1
}

:-moz-placeholder {
	color: #CFCECE;
	opacity: 1
}

.btn {
	padding: 10px 20px;
	font-size: 16px;
	color: #000;
	font-weight: 500;
	outline: none;
	box-shadow: none!important;
	border-radius: 5px;
	font-family: 'aileronsemibold';
}
.btn-sm {
	padding: 4px 15px;
}

.btn-border {
	border: 2px solid #000
}

.btn-border:hover,
.btn-border:focus {
	background: #ff6363;
	color: #fff;
	border-color: #ff6363
}

.btn-primary {
	background: #ED4E94;
	color: #fff;
	border-color: #ED4E94;
}

.btn-primary:hover,
.btn-primary:focus,.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
	opacity: .95;
	border-color: #ED4E94;
	background: #ED4E94;
	color: #fff;
}

.button_purpal{
    background: #5a429b;
    padding: 10px 30px;
    color: #fff; min-width:120px;
    display: block;}
.button_purpal:hover{background:#4a328a; color:#fff;}
	
section {
	overflow: hidden
}

#top_menu {
	padding: 20px 0;
	position: absolute;
	z-index: 99;
	top: 0;
	left: 0;
	right: 0;
}
.nav-scroll .header2 {background:#fff!important;padding-top: 5px; padding-bottom: 5px;}
#top_menu.nav-scroll, #top_menu .header2.nav-scroll {background:#fff!important;
    position: fixed!important;
    top: -100px!important;
    left: 0;
    width: 100%;    z-index: 999!important;
    -webkit-transition: -webkit-transform .5s;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
    -webkit-box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.05);
    padding-top: 5px;
    padding-bottom: 5px;
}
.nav-scroll .filter {    margin-top: 0;}
.product_header.nav-scroll {
    padding: 0px!important;
}
.nav-scroll .header-top li a:not(.btn){
	color:#000
}
.nav-scroll .header-top {margin-bottom: 0px;}
.nav-scroll .header-top .cart sup {background-color: #ececec;}
.nav-scroll .icon1{display:none}
.nav-scroll .icon2{ display:inline-block}
.icon2{ display:none}
.nav-scroll .navbar {
	align-items: center;
}
.nav-scroll .navbar .navbar-nav li a.nav-link {padding: 2px 15px;}
.nav-scroll .navbar .navbar-nav li a.nav-link:not(:hover) {
	color: #5A429B;
	border-color: #5A429B;
}

.navbar .search-box {
	display: none;
	width: 32%;
	border: 1px solid #ED4E94;
	
}
.navbar .search-box select {
	padding: 14px 40px 14px 10px;
	margin-bottom: -1px;
}
.nav-scroll .navbar .search-box {    width: 35%;
	display: flex;    margin-top: -20px;
}
.search-input {
	width: 65%;
}
.navbar .search-input {
	width: 63%;
}
.header-top {
	display: flex;
	flex-wrap: wrap;    min-height: 30px;
	align-items: center;
	justify-content: flex-end;
	margin-bottom: -5px;
	padding-left: 0;
	position: relative;
	z-index: 1;
}
.header-top li {
	list-style: none;
	padding-left: 30px;
}
.header-top li a:not(.btn) {
	font-size: 14px;
	color: #fff;
	display: flex;
  align-items: center;
}
.header-top .cart {
	position: relative;
}
.header-top .cart sup {
	    width: 24px;
    height: 24px;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -11px;
    font-size: 14px;
    color: #ED4E94;
    top: -8px;
}
.navbar {
	padding: 0;
	align-items: flex-end;
}
.user_drop .nav-link{ color:#fff}
.nav-scroll .user_drop .nav-link{ color:#000}
.navbar-toggler:focus {
	outline: none;
	box-shadow: none;
}
.navbar-brand {
    padding: 30px 15px 13px;
    background: #fff;
    border-radius: 0 0 25px 25px;
    margin: -60px 20px 16px 0;
    width: 145px;
    -webkit-box-shadow: 0px 5px 5px rgb(0 0 0 / 20%);
    box-shadow: 0px 5px 5px rgb(0 0 0 / 20%);
	position: relative;
    z-index: 2;
	
}
.nav-scroll .navbar-brand {max-width: 80px;    box-shadow: none;
    margin: -15px 15px 8px 0px;
    padding: 0;
}
.lightSlider.lsGrab img{ width:100%}
.navbar .navbar-nav li:last-child {
	padding-right: 0;
	padding-left: 20px;
}
/*.navbar .navbar-nav li:nth-last-child(2) {
	padding-left: 35px;
}*/
.navbar .navbar-nav li a.join-btn {
	border: 1px solid #fff;
	border-radius: 6px;
}

.navbar .navbar-nav li a.nav-link {
	font-size: 20px;
	color: #fff;
	font-family: 'aileronsemibold';
	padding: 4px 15px;
	transition: all .2s ease-in-out
}
.navbar .navbar-nav.nav-color-blck li a.nav-link {
    font-size: 20px;
    color: #877f87;
    font-family: 'aileronsemibold';
    padding: 4px 15px;
    transition: all .2s ease-in-out;
}
.navbar .navbar-nav li a.nav-link:hover {
	color: #ED4E94;
}
.navbar .navbar-nav li a.join-btn:hover {
	background-color: #ED4E94;
	color: #fff;
	border-color: #ED4E94;
}
.dropdown-toggle:after {
	display: none
}
.nav-scroll .scroll_space_remove{ display:none}
.navbar-toggler {
	position: absolute;
	right: 0;
	top: 10px;
	z-index: 1111;
	padding: 0;
	border-radius: 0;
	width: 45px;
	height: 45px;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	transition: all ease .4s
}
.navbar-light .navbar-toggler {
	border-color: #fff;
    background-color: #fff;
    position: absolute;
    top: -20px;
    right: 0;
    border-radius: 5px;
}

.navbar-toggler img {
	width: 100%
}

.dropdown-menu {
	min-width: 270px;
	right: 0;
	/* left: auto; */
	box-shadow: 0 9px 19px 7px rgba(0, 0, 0, .07);
	border: none;
	border-radius: 8px;
	padding: 0;
	transition: all ease .6s;
	display: none
}

.dropdown-menu a.dropdown-item {
	padding: 5px 20px;
	font-size: 16px;
	font-weight: 400;
	position: relative
}

.dropdown-menu a.dropdown-item img {
	margin-right: 10px;
	width: 20px
}
.banenr-section .carousel-caption {
	left: 0;
	right: 0;
	text-align: left;
	top: 60%;
	bottom: auto;
	transform: translateY(-50%);
}

.banenr-section h1 {
	font-size: 45px;
	color: #fff;
	line-height: 54px;
	font-family: 'aileronbold';
}
.banenr-section h1 small {
	display: block;
	font-size: 36px;
	line-height: 43px;
}
.carousel-indicators li {
	height: 5px;
	border-radius: 11px;
	border: none;
	opacity: 1;
}
.carousel-indicators .active {
	background-color: #ED4E94;
}
.search-box {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	position: relative;
	width: 100%;
	background-color: #fff;
	border-radius: 8px;
	overflow: hidden;
	margin-top: 10px;
}
select {
	-webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.search-box select {
	width: 145px;
	background-color: #ED4E94;
	font-size: 13px;
	color: #fff;
	padding: 12px 50px 12px 15px;
	background-image: url(../images/chevron-thin-down.png);
	background-repeat: no-repeat;
	background-position: center right 19px;
	background-size: 12px;
	border: none;
}
.search-btn {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	background-color: transparent;
	border: none;
	right: 19px;
}
.search-input input {
	border: none;
	width: 100%;
	padding-left: 13px;
	font-size: 13px;
	color: #000;
}
.middle-section {
	padding: 40px 0;
}
.custom-card {
	flex-direction: row;
	border: none;
	border-radius: 16px;
	padding: 8px 12px;
	width: 100%;
	align-items: flex-end;
	height: 100%;
}
.middle-section .row {
	margin-right: -8px;
	margin-left: -8px;
}
.middle-section [class*="col"] {
	padding-left: 8px;
	padding-right: 8px;
}
.bg-light {
	background-color: #f5f5f5 !important;
}
.custom-card h2 {
	color: #232222;
	opacity: 0.57;
	font-size: 36px;
	padding-bottom: 0;
	font-family: 'aileronbold';
	line-height: 1.1;
}
.custom-card p {
	line-height: 1.2;
	font-size: 15px;
	color: #232222;
	opacity: 0.57;
	padding-bottom: 0;
}
.card-left {
	width: 25%;
}
.card-right {
	width: 70%;
    padding: 4px 0 15px 20px; position:relative;
}
.shop-btn {
	    position: absolute;
    right: 20px;
    top: 40%;
    width: 25px;
}
.pt-65 {
	padding-top: 65px;
}
.pb-65 {
	padding-bottom: 65px;
}
.pb-80 {
	padding-bottom: 80px;
}
.p80 {
	padding: 80px 0;
}

.pt-100 {
	padding-top: 100px;
}
.pb-100 {
	padding-bottom: 100px;
}
.pt-50 {
	padding-top: 50px;
}
.pb-50 {
	padding-bottom: 50px;
}
.pt-90 {
	padding-top: 90px;
}
.pb-90 {
	padding-bottom: 90px;
}
.pt-35{
		padding-top: 35px;
	}
.heading-title {
	text-align: center;
}
.ttu{text-transform: uppercase;}
.heading-title h2 {
	
	font-size: 45px;
	font-family: 'aileronbold';
	color: #232222;
	padding-bottom: 0;
}
.heading-title p {
	font-size: 24px;
	color: #474747;
}
.shop-heading {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}
.shop-heading h3 {
	font-family: 'aileronbold';
	font-size: 24px;
	color: #1A1A1A;
	padding-bottom: 0;
}
.btn-link {
	color: #ED4E94;
	font-size: 18px;
}
.btn-link:hover {
	color: #8C32E7;
	text-decoration: none;
}
.shop-heading .btn-link {
	margin-left: auto;
}
.shop-item {
	border: 1px solid #b0b0b0;
	border-radius: 16px;
	padding: 10px;
	position: relative;
	margin-right: 1px; background:#fff;
}
.owl-carousel .owl-item .item-img {
	    width: auto; display:block;
    height: 100px;
}
.shop-item h4 {
	font-size: 14px;
	color: #272626;
	line-height: 1.2;
	font-family: 'aileronregular';
	font-weight: normal;
	min-height: 60px;
}
.shop-item h4 a {
	color: inherit;
}
.shop-item h4:hover {
	color: #8C32E7;
}
.rating-box {
	font-size: 18px;
	font-family: 'aileronbold';
	color: rgba(26,26,26,0.70);
	display: flex;
	align-items: center;
	margin-left: auto;
}
.rating-box img {
	margin-right: 5px;
}
.shop-footer {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}
.price {
	font-size: 18px;
	color: #1A1A1A;
	line-height: 1.4;
}
.price strong {
	font-family: 'aileronbold';
}
.price del {
	font-size: 14px;
	color: #ACACAC;
	display: block;
}
.owl-carousel .owl-item .add-cart img{width:auto}
.add-cart {
	background-color: #5A429B;
	width: 36px;
	height: 36px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 11px;
	right: 11px;
	z-index: 1; color:#fff;
}
.add-cart:hover {
	background-color: #811EE5;
}

.items-carousel .owl-nav {
	font-size: 36px;
  position: absolute;
  top: -34px;
  right: 80px;
  line-height: 0.6;
}
.items-carousel .owl-nav .owl-prev {
	margin-right: 6px;
}
.item-body {
	padding: 8px 7px 0;
}
.rounded-11 {
	border-radius: 11px;
}
.item-style-two h4 {
	margin-top: 0;
	width: 73%;
	padding-bottom: 0;
	min-height: auto;
}
.badge-left {
	display: flex;
	align-items: center;
	width: 85%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.item-badge {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	padding-bottom: 8px;
}
.sheld {
	margin-left: auto;
}
.badge-img {
	width: 30px;
	height: 30px;
	min-width: 30px;
}
.badge-left span {
	font-size: 18px;
	font-family: 'aileronsemibold';
	text-overflow: ellipsis;
	overflow: hidden;
}
.badge-left span + span {
	padding-left: 6px;
}
.sluton-header {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	padding-bottom: 10px;
}
.sluton-header span {
	width: 40px;
}
.sluton-header h4 {
	width: calc(100% - 40px);
	padding-left: 15px;
	font-size: 24px;
	font-family: 'aileronsemibold';
	color: #1A1A1A;
	padding-bottom: 0;
}
.solution-card {
	padding-right: 50px;
	padding-bottom: 80px;
}
.solution-card p {
	color: #272626;
	line-height: 17px;
	font-size: 14px;
}
.bg-blue-light {
	background-color: #F3F2F8;
}
.feature-list {
	padding: 20px;
}
.feature-list img{width: 40px;}
.feature-list h3 {
	font-size: 24px;
	font-family: 'aileronsemibold';
	color: #232222;
	line-height: 30px;
	padding-bottom: 10px;
	padding-top: 25px;
}
.feature-list p {
	line-height: 25px;
	color: #232222;
}
.download-appbox p {
	font-size: 24px;
	line-height: 45px;
	color: #454153;
}
.download-appbox h2 {
	font-size: 45px;
	font-family: 'aileronbold';
	color: #454153;
	text-transform: uppercase;
}
.download-appbox h5 {
	font-size: 30px;
	font-family: 'aileronbold';
	color: #454153;
	text-transform: uppercase;
}
.app-btn {
	padding-top: 10px;
}
.app-btn a {
	margin-right: 15px;
}
.newsletter-section {
	padding: 40px 0 35px;
	background: rgb(90,66,155);
	background: -moz-linear-gradient(90deg, rgba(90,66,155,1) 0%, rgba(23,118,187,1) 100%);
	background: -webkit-linear-gradient(90deg, rgba(90,66,155,1) 0%, rgba(23,118,187,1) 100%);
	background: linear-gradient(90deg, rgba(90,66,155,1) 0%, rgba(23,118,187,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#5a429b",endColorstr="#1776bb",GradientType=1);
}
.newsletter-section h3 {
	color: #fff;
	font-size: 24px;
	font-family: 'aileronsemibold';
	line-height: 35px;
	padding-bottom: 0;
}
.newsletter-section p {
	font-size: 18px;
	line-height: 35px;
	color: #fff;
	padding-bottom: 0;
	font-family: 'Century Gothic';
}
.newsletter-box {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}
.newsletter-box .form-control {
	height: 60px;
	max-width: 495px;
	border-radius: 14px;
}
.newsletter-box .btn-primary {
	height: 60px;
	min-width: 235px;
	font-family: 'Century Gothic';
	text-transform: uppercase;
	font-size: 22px;
	margin-left: 24px;border-radius: 14px;
}
.newsletter-box .btn-primary:hover {
	background-color: #c3276c;
	border-color: #c3276c;
}
footer{
	background-color: #fff;
	padding-top: 75px;  position:relative;   z-index: 9;
}
.footer-menutitle {
	font-size: 24px;
	font-weight: normal;
	color: #232222;
	line-height: 1.6;
	padding-bottom: 0;
}
.footer-menu {
	padding-left: 0;
}
.footer-menu li {
	list-style: none;
}
.footer-menu li a {
	font-size: 16px;
	color: #232222;
	line-height: 1.8;
}
.footer-menu li a:hover {
	color: #ED4E94;
}
.footer-bottom {
	margin-top: 50px;
	border-top: 1px solid rgba(112,112,112,0.35);
	padding-top: 16px;
}
.copyright img {
	margin-right: 30px;
}
.footer-bottom .social-links {
	padding-bottom: 46px;
}
.copyright {
	font-size: 18px;
	color: #232222;
	line-height: 1.9;
}
.social-links {
	padding-left: 0;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	margin: 0 -8px;
}
.social-links li {
	list-style: none;
	padding: 8px;
}
.social-links li a {
	width: 52px;
	height: 52px;
	border-radius: 50%;
	background-color: #5A429B;
	display: flex;
	align-items: center;
	justify-content: center;
}
.social-links li a:hover {
	background-color: #811EE5;
}
.social-title {
	font-size: 24px;
	font-weight: normal;
	color: #232222;
	padding-bottom: 15px;
}
.payment-method {
	text-align: center;
}
.payment-method img {
	margin-left: 25px;
	margin-right: 0;
}
.nav-scroll .navbar .search-box select {padding: 7px 25px 7px 7px;    width: 110px;}
.nav-scroll .search-btn {
    right: 5px;
    width: 28px;
}
.find_work{ padding:15px; border:1px solid #1776bb; color:#1776bb; border-radius:15px;  display:block;}
.post_a_job{ padding:20px 25px; border:1px solid #1776bb; color:#1776bb; border-radius:15px;  display:block;}
.post_a_job2{background:#5a429b; padding:10px 30px; color:#fff; border-radius:15px;display:block;}
.btn_man{ background:#5a429b; color:#fff; border:1px solid #5a429b}
.btn_man:hover{ background:#31205e; color:#fff;}
.post_a_job2 h5{     line-height: 23px;}


.find_work{    padding: 15px;
    color: #1776bb; border-radius: 10px;
    display: block;
    border: 1px solid #1776bb;
    text-align: center;
    font-weight: 600;}
.find_work_bg{ background:#1776bb; color:#fff; }
.find_work:hover{ background:#1776bb; color:#fff;}

#aboutus video{    width: 100%;}
.extraordinary-card h5{     padding-bottom: 0;}
.extraordinary-card span{    min-width: 50px; margin-right: 10px;}
.extraordinary-card p{line-height: 18px;    font-size: 13px;}
.extraordinary-card {  display:flex;}
.logo_shadow{     -webkit-box-shadow: 2px 3px 5px rgb(0 0 0 / 20%);    box-shadow: 2px 3px 5px rgb(0 0 0 / 20%);}
.nav-scroll .logo_shadow{box-shadow:none}






/*========servicex======*/
.popular_servicese{    -webkit-box-shadow: 2px 3px 5px rgb(0 0 0 / 20%);
    box-shadow:2px 3px 5px rgb(0 0 0 / 20%);
    padding: 20px 10px;
    border: 1px solid #aeaeae; margin-bottom:40px;
    border-radius: 20px;    display: block;
    width: 100%;}
.popular_servicese h5{    padding: 10px 0 0;   }
.popular_servicese p{ color:#ed4e94;line-height: 20px;}
.popular_servicese img{ width: 70px;}
.bg1{ background: #5a429b; color:#fff;}
.bg1 h2, .bg1 p{color:#fff;}
.tl{text-align:left}

.wite_line_btn:hover{    color: #5a429b; background:#fff;}
.wite_line_btn{    color: #fff;
    border: 1px solid #fff;
    padding: 12px 40px;}
.fs18{ font-size:18px}

.a-business-section li{ margin-bottom:20px; display:block; position:relative;padding-left:35px;}
.a-business-section li:after{      content: "\f00c";
    font: normal normal normal 14px/1 FontAwesome;
    position: absolute;
    left: 0;
    font-size: 20px;
    top: 0;}
.were_world .tab-content ul li{ display:inline-block; 
   width: 25%; padding:0 15px 20px; color:#555556;}
.were_world .nav{ display:block}
.were_world .nav li{ display:inline-block}
.were_world .nav li a{ display:inline-block; border-bottom:10px solid #fff;    font-size: 22px;}
.were_world .nav li a.active{border-bottom:8px solid #ed4e94}

.growing_box{ background:#83d1e9; color:#fff;}
.growing_box h3{font-size: 40px;}
.banner_p{font-size: 17px;}
.product_banenr {position: relative;     padding: 90px 0 30px;background: rgb(23,118,187);
background: linear-gradient(128deg, rgba(23,118,187,1) 0%, rgba(58,90,170,1) 50%, rgba(90,66,155,1) 100%);}
.banner_shopnow {     font-size: 20px;
    color: #fff;
    display: flex;
    margin: -38px 0 0;
    position: relative;}
.product_service_box{    display: flex;-webkit-box-align: center;
					-ms-flex-align: center;
					align-items: center;}
.product_service_box .icon_img{  height: 70px;   margin-right: 15px;}
.product_service_box .icon_img img{    height: 100%;    max-width: initial; }
.product_service_box h4 span{    display: block;
    font-size: 13px;
    font-weight: 200;
    margin-top: 5px;}
.product_service{border-top: 1px solid #2d2d2d;
    border-bottom: 1px solid #2d2d2d;
    margin: 50px 0; padding:25px 0}
.std .product_service_box{border: 1px solid #b0b0b0;
    padding: 20px;
    border-radius: 10px;}

.make_it_real{    -webkit-box-shadow: 2px 3px 5px rgb(0 0 0 / 20%);
    box-shadow: 2px 3px 5px rgb(0 0 0 / 20%);
     border: 1px solid #aeaeae;
    border-radius: 20px;
    display: block;overflow: hidden;
    width: 100%;}

.make-body{    font-weight: 600;}
.make-body span{    font-weight: 500; font-size:13px; display:block;}

.in_banenr{ position:relative}

.banner_content{text-align: center;
    position: absolute;
    width: 100%;
    bottom: 20px;
    color: #fff;}

.banner_content h1{    font-size: 40px;}
.banner_content p{    font-size: 20px;}
.tc{ text-align:center}
.tr{ text-align:right}
.why_ict h4{  margin:10px 0 0}
.convetional_banenr .btn{ min-width:150px}
.nav-scroll .navbar2 .navbar-brand { margin: 5px 15px 8px 0px; }
#SignIn { margin: 5px 15px 8px 0px; }
#SignIn .close{ position: absolute; right: 16px;  top: 8px; font-size: 40px;}
.login_social .social-icon-login{    width: 50px; display:inline-block;    margin: 8px;}
button.social-icon-login.metro {
    border: none;
    background: no-repeat;
}
.fs18{ font-size:18px;}
.op08{opacity:0.8}
.or{position:relative; 
	/* margin:15px 0 */
}
.or:after{position: absolute;
    left: 30%;
    width: 40%; top: 19px;
    content: "";
    background: #b7b7b7;    height: 2px;}
.or span{     background: #fff;
    height: 40px;
    width: 40px;
    display: inline-block;
    border-radius: 100%;
    line-height: 40px;
	position: relative;
    z-index: 9; color:#b7b7b7;}
.login_lable{ font-size:17px; font-weight:600}
.w100{ width: 100%;}
.color1{ color:#5a429b}
.color3{ color:#ed4e94}
.color_man{ color:#ed4e94}
.text-black{ color:#4b4848!important; }
.join_us_page{ padding:100px 0 50px}
.join_us_page h3{  font-weight:400}
.join_us_page h2{  font-weight:700; font-size:50px}
.join_us_right{ background:#fff; padding:40px 30px; border-radius:20px}
.form-control2{background-color: #f6f6f6;
    border: none;
    width: 100%;  padding:8px 15px;
    height: 45px;
    border-radius: 10px;}
.input_login{position:relative}
.input_login .form-control{ padding:8px 8px 8px 50px;height: 45px;}
.input_login .login_icon{ position: absolute;
    top: 12%;
    height: 75%;
    line-height: 36px;
    width: 35px;    left: 10px;    padding-right: 8px;    border-right: 1px solid #ccc;}
.m0a{ margin:0 auto;}	
.become_partner .form-control{    border: 1px solid #292828;    height: auto;
    padding: 12px 10px;}	
.become_partner textarea.form-control{    height: 120px; }	
.df{     display: flex;}	
.upload_btn i{ font-size: 22px;}
.upload_btn{background: #e4e4e5;
    color: #5a429b;
    font-weight: 600;
    padding: 16px;
    position: relative;
    font-size: 18px;
    height: 53px;
    border-radius: 10px;}	
.upload_btn input{    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;}	
.tj{ text-align:justify}
.skills_btn{ border:1px solid #707070; color:#707070; margin:0  15px 10px 0; padding: 5px 15px;border-radius:25px}
.skills_btn i{    color: #999;    margin-left: 15px;}
.control_box{ border:1px solid #292828; padding:20px; border-radius:8px}	
.control_box .enter_skills{    border: none;
    width: 100%;
    margin-top: 20px;}	
	
.post_a_service_box {  margin-bottom:20px;
       -webkit-box-shadow: 2px 3px 5px rgb(0 0 0 / 20%);
    box-shadow: 0px 2px 5px rgb(0 0 0 / 20%);
 
    border-radius: 15px;
    display: flex;
    width: 100%; padding:20px; position:relative;
}
.post_a_service_box .icon_img{    min-width: 75px;}
.post_a_service_box p{ line-height: 20px;    font-size: 13px;}
.posican_box_left{left: 0;
    border-radius: 0 25px 25px 0;
    display: inline-block;
    padding: 8px 20px;
    position: absolute;
    bottom: 20px;
    color: #fff;}
.posican_box_right{    margin-right: -20px;    background: #ed4e94;    border-radius: 25px
 0 0 25px;    display: table;    padding: 8px 20px;    color: #fff;    margin-left: auto;}
.bg11{ background:#1776bb;}
.bg2{ background:#2c9f2c;}
.bg3{ background:#ff9b06;}
.bg4{ background:#271261;}
.bg5{ background:#5da1d4;}
.bg6{ background:#0e69ab;}
.bg7{ background:#ea5e5d;}
.bg8{ background:#5a429b;}
.bg10{ background:#2c9f2c;}

.co1{ color:#1776bb;}
.co2{ color:#2c9f2c;}
.co3{ color:#ff9b06;}
.co4{ color:#271261;}
.co5{ color:#5da1d4;}
.co6{ color:#0e69ab;}
.co7{ color:#ea5e5d;}
.co8{ color:#5a429b;}
.co10{ color:#2c9f2c;}



.h100{ height:100%}
.become_partner .price_input{ border:1px solid #707070; height:50px}
.input-group-text{    background: none;
    border: 1px
 solid #707070;
    min-width: 70px;
    text-align: center;}
.alert_bg{background:#efefef}
	
.popricing_box .row{     width: 100%;}
.popricing_box { 
       -webkit-box-shadow: 2px 3px 5px rgb(0 0 0 / 20%);
    box-shadow: 0px 2px 5px rgb(0 0 0 / 20%);
   margin-bottom: 30px;
    border-radius: 10px;border:1px solid #f1f1f1;
    display: flex;
    width: 100%; padding:15px; position:relative; overflow:hidden
}	
.m_a_0{ margin:auto 0}	
.checkbox{ width:20px; height:20px}	
.f_stake{    border-radius: 0 25px 25px 0;    display: block;    padding: 8px 20px 8px 40px;    color: #fff;    width: 150px
;    margin: 0 0 15px -15px;}	
.flex{ display:flex;}
.rebuild_ebsite{     width: auto;
    margin: 0 10px 10px 0;    display: inline-block;    padding: 8px 25px;}
select{    background-image: url(../images/drop.png);
    background-position: center right 10px;
    background-repeat: no-repeat;
    background-size: 11px;
;}
.tab_box .post_a_service_box{ border: 1px solid #fff;}
.tab_box .active .post_a_service_box{ border: 1px solid #ed4e94;}
	
.stating {
    font-size: 16px;
    margin-left: auto;
    font-weight: 600; text-align:right;
}
.stating span {
    font-size: 11px;
    display: block;
    text-transform: uppercase;
    opacity: 0.8;
}
.product_rating_img {
    object-fit: cover;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    margin-right: 7px;
}
.content_font{font-size: 13px;}	
.edit {
    background: #f6f6f6;
    border: 1px solid #e6e6e6;
    padding: 4px 10px;
}
.tr_drop{ text-align:right}	
.row2{    margin-right: -8px;  margin-left: -8px;}
.row2 .col-6{    padding-right: 8px; padding-left: 8px;}
.row2 .col-lg-2, .row2 .col-lg-3{    padding-right: 8px; padding-left: 8px;}
.row3 .col-lg-3{    padding-right: 8px; padding-left: 8px;}
.product_header {
    background: rgb(23,118,187);
    background: linear-gradient(128deg, rgba(23,118,187,1) 0%, rgba(58,90,170,1) 50%, rgba(90,66,155,1) 100%);
	padding-bottom:0!important
}
.header2 .navbar .search-box{ display:flex;    margin-top: 0;}
.filter {
    background: #fff;
    border-bottom: 1px solid #d9d9d9;
    margin-top: 5px;
}
.short_by{ border-radius:5px; padding:4px 30px 4px 4px;    width: 140px;}
	
.drop_collapse {
    -webkit-box-shadow: 0 12px 18px rgb(0 0 0 / 14%);
    box-shadow: 0 12px 18px rgb(0 0 0 / 14%);
    position: absolute;
    z-index: 9;
    left: 0;border: 1px solid #dadbdd;
        top: 100%;
    background: #fff;
    width: 250px;
}
/*.content-scroll {    min-height: 250px;overflow-y: scroll;}*/
.category_list li{  margin-top:8px; display:block;}
.filter_box{     position: relative; padding:12px;border-left: 1px solid #ccc;     width: 100%;}
.row-flex .filter_box:last-child{ border-right: 1px solid #ccc; }
.menu_title{ position:relative; cursor:pointer}
.menu_title .fa{ position:absolute; right:0; top:5px}
.w125{width: 125%;}
#MoreFilter{width: 600px;
    right: 0;
    left: auto;
}
.form-check .count {
    color: #b5b6ba;
    margin-left: 6px;
}	
.w150{ width: 550px;}
.w300{ width: 300px;}
.btn-small{ padding: 3px 10px;}
.b{ font-weight:600}	
.ccc{color: #919191;}	
.row-flex{display: -ms-flexbox;    display: flex;}	
.scrool_tab li{ display: inline-block;}	
.scrool_tab li a {
    font-size: 14px;
   
    font-weight: 600;
    display: inline-block;
    color: #545454;
    -webkit-transition: color .2s;
    -o-transition: color .2s;
    transition: color .2s; padding:12px; border-bottom:2px solid #fff
	
}
.scrool_tab li a.active{color: #007bff;border-bottom:2px solid #007bff}	
.nav-scroll .filter {
    border-top: 1px solid #d9d9d9;
}

.lcp_icon a{border-radius:3px; border:1px solid #d2d1d1; display:inline-block; margin-left:5px; width:30px; height:30px; line-height:30px; color:#ed4e94; text-align:center}
.breadcrumb{ padding:0; background:none;}
.breadcrumb li{margin-right:20px; position:relative;}
.breadcrumb li:last-child:after { display:none}
.breadcrumb li:after {
    position: absolute;
    content: "/";
    right: -13px;
    top: 0;
}
.star_revi i{ font-size:18px; color:#ffb300; margin-right:4px}
h3 strike{ font-size:20px;     font-weight: 400;}
.delivery_box{ display:flex; padding:20px; background:#f6f6f6; border-radius:10px;margin-bottom:20px}
.delivery_box img{ width:80px}
.questions_box img{ width:100px}
.questions_box {font-size: 12px;
    display: flex;
    border: 1px solid #d4d4d4;
    padding: 15px ; margin-top:10px;
	    border-radius: 10px;
}
.questions_box a { color:#1776bb}
.questions_box h5 { padding-bottom:0}
.questions_box p{    font-size: 12px;}
.questions_box .btn_man {
    padding: 5px 10px;
    font-size: 14px;color:#fff
}
.questions_box img {
    width: 90px;
    margin-right: 10px;
}
.pickup_box{ display:flex; margin-top:20px}
.pickup_box img { width: 50px;    height: 50px;}
.lh32{    line-height: 32px;}
.d_block{ display:block}
.specifications_list li{
	 display:block;
     -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
	padding-right: 15px;
    padding-left: 15px; font-size:15px; margin-bottom:20px;
}
.about_seller{ display:flex;
}
.about_seller .img {
    object-fit: cover;
    border-radius: 50%;
    height: 100px;
    width: 100px;
}
.seller_name {
    font-size: 22px;
    opacity: 0.8;
}
.item-body .item_name{ font-weight:600; font-size:17px}


.accordion-section .panel-default {
    border-bottom: 1px solid #3a3a3a;    padding-bottom: 10px;  margin-bottom:30px
}
.accordion-section .panel-heading a {
    display: block;
    font-size: 1.1rem;
}
.accordion-section .panel-heading a:after {
    font-family: 'FontAwesome';
    font-style: normal;
    font-size: 2rem;
    content: "\f106";
    color: #979797;
    float: right;
    margin-top: -11px;
}
.accordion-section .panel-heading a.collapsed:after {
    content: "\f107";
}
.accordion-section .panel-default .panel-body {
    font-size: 1.2rem;opacity: 0.8;
}
.border_box{
    border: 1px solid #b0b0b0;
    border-radius: 15px;
    padding: 10px;
}
.reviews_box h2{ color:#5c9fcf; padding-bottom:0}
.reviews_rating i{ color:#ffb300; font-size:20px; }
.star_progress{ display:flex; margin:5px 0}
.yellow{color:#ffb300; }
.star_progress .progress{ width: 100%;    height: 7px;}
.d_flex{ display:flex}
.space_header {
    height: 190px;
}
.premlum_right{ font-size: 1.5rem;    width: -webkit-fill-available; padding-top:20px;}
.f13{ font-size: 13px;}
.psp_tab li{ text-align:center;    width: 32%;}
.psp_tab a{ color:#363636; font-size:17px; font-weight:600;}
.psp_tab a.active{ color:#ed4e94;}
.more_tab{width: 230px;}
.more_tab li{ display:block;    width: 100%;}
.more_tab li a{padding:4px 0; display:block; position:relative;}
.more_tab li a.active:before {
    content: "\f0da";
    font: normal normal normal 14px/1 FontAwesome;
    position: absolute;
    right: 0;
    top: 2px;
    font-size: 22px;
    color: #ed4e94;
    z-index: 9999;
}

.more_filter{background:#ebebeb}
.more_filter .tab-content {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 999;
    background: #fff;
    width: 60%;
    height: 100%;
	    -webkit-box-shadow: 0 12px 18px rgb(0 0 0 / 14%);
    box-shadow: 0 12px 18px rgb(0 0 0 / 14%);
}
.user_drop img{    width: 38px; margin-right: 10px;}
nav.navbar {    -webkit-box-align: center;
					-ms-flex-align: center;
					align-items: center;}
.user_drop .fa-angle-down{    font-size: 26px;    margin-left: 6px;}
.menu2{    background: #efefef;  padding: 30px 0 0;}
.menu2 ul{ display: flex;}
.menu2 ul li{ margin-right:2.2%; display:block}
.menu2 ul li a{ position:relative; padding-bottom:20px ;display: block;}
.menu2 ul li a.active{ border-bottom:3px solid #ed4e94}
.space_dashbord{    height: 110px;}	
.menu2 ul a sup {
    width: 16px;
    height: 16px;
    background-color: #ed4e94;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 35%;
    font-size: 12px;
    color: #ffffff;
    top: -15px;
}
.notifications_span {
    width: 27px;
    height: 27px;
    background-color: #ed4e94;
    border-radius: 100%;
    text-align: center;
    justify-content: center;
    font-size: 15px;
    font-weight: 500;
    line-height: 30px;
    color: #ffffff;
    display: inline-block;
}
.nav-scroll .menu2{ display:none} 	
.dashbord_in{  background:#f3f3f3; padding:30px 0} 	
.dashbord_welcome h2{ font-size:35px}
.dashbord_welcome {
    padding: 15px 0 10px;
}
.d_boxs{ background:#fff; border-radius:10px; padding:20px} 	
.mla{margin-left:auto} 	
.fw400{font-weight:400} 	
.notifications_boxs{color:#7D7D82; display:flex; font-size:13px; line-height:19px; margin-top:10px} 	
.notifications_boxs img{
    object-fit: cover;
    border-radius: 50%;
    height: 50px;
    width: 50px; margin-right:15px
} 	
.date_time{ display:block} 	
.box_hadding{ color:#2B2B2B; padding:0; font-weight:400} 	
.box_hadding_btn{    display: flex;margin-top:5px}
.box_hadding_btn a {
    color: #5A429B;
    font-weight: 500;
    padding:0 10px;
    border-right: 1px solid #5A429B;
    font-size: 13px;
}
.box_hadding_btn a:last-child{ border-right:0px solid #5A429B; } 	
.box_hadding_btn a:first-child{ padding-left:0; } 	
.boxs_list li{  display:block; padding:10px 0 ;font-size:13px} 	
.quick_links li{  display:block; padding:5px 0 ;font-size:15px} 	
.quick_links li a{color:#5A429B} 	
.quick_links li a.active{    color: #ED4E94;    border-bottom: 1px solid #ED4E94;} 	
.boxs_list li .btn-link{font-size:13px} 	
.boxs_list li h6{font-weight:400; padding-bottom:0; font-size:15px} 	
.dashbord-heading .btn-link{ margin-left:auto}
.dashbord-heading{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.ml_auto{ margin-left:auto}
.wmc{width: max-content;}
.search_input input{    font-size: 12px;    width: 60%;}

.dashbord_in_man {
    padding: 25px 20px 25px 0px;display: inline-flex;width: 100%;
}

.dashbord_box1 {
    background: rgb(237,78,148);
    background: linear-gradient(119deg, rgb(221 53 127) 0%, rgb(237 131 177) 100%);
}
.dashbord_box2 {
    background: rgb(29,114,176);
    background: linear-gradient(119deg, rgb(19 93 147) 0%, rgb(51 153 227) 100%);
}
.dashbord_box3 {
    background: rgb(161,126,255);
    background: linear-gradient(119deg, rgb(127 97 209) 0%, rgb(85 61 153) 100%);
}
.dashbord_box4 {
    background: rgb(111,111,111);
    background: linear-gradient(119deg, rgb(66 66 66) 0%, rgb(94 94 94) 100%);
}
.dashbord_box{ border-radius:10px; padding:20px;  color:#fff; margin-bottom:20px; position:relative}
.dashbord_box:before {
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    background: url(../images/dashbord.png) center;
    background-size: cover;
    height: 100%;
    position: absolute;
    opacity: 0.15;
}
.hadding_name{ display:flex;  position:relative}
.hadding_name img{height: 25px; }
.dashbord_box h3{  margin:20px 0 10px; position:relative}
.hadding_border{border-bottom:1px solid #dddddd; margin-bottom:20px}
.venter_img{    object-fit: cover;
    border-radius: 50%;
    height: 50px;
    width: 50px;
  }
.venter_img{    object-fit: cover;
    border-radius: 100%;
    height: 60px;
    width: 60px;
   }	
.vender_user{    object-fit: cover;
    border-radius: 100%;
    height: 100px;
    width: 100px;
   }
.venter_product{ height:50px}
.table_vender{    width: 100%;}
.table_vender tr{  margin-bottom:20px;}
.table_vender th{ border:1px solid #5a429b;font-size: 14px;    padding: 10px; background:#f8f6fc ;   
 min-width: 110px;}
.table_vender td{ border:1px solid #cbc7c7 ;    padding: 10px;}
.table_responsive_scrool{ min-height:500px; overflow-y:audio}
.table_responsive_scrool th{position: sticky;}
.vender_user_name{position: relative; display:inline-block; padding-right:30px}
.vender_user_name {font-size: 14px; margin:30px 0 20px}
.vender_user_name  h5 {font-size: 18px;}
.vender_user_name img {
    position: absolute;
    right: 0px;
    top: 5px;
}
.premiu_box{ background:#f5f5f5; padding:5px; border-radius:8px; margin-bottom:20px}
.premiu_box  h5 {font-size: 16px;}
.premiu_box  img {    min-width: 39px;}
.search_input select {    width: 150px;}
.w90 {  width: 90px;}

.info_box li{ display:block;
    max-width: 25%;
	 position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
	font-size: 18px; margin-bottom: 35px; 
	}
.info_box li small {
    font-size: 14px;margin-bottom:5px;
    display: block; color:#8B8B8B;
   
}
.nav-left{ display:block}
.my_profile_tab li a{ width:100%}
.my_profile_tab li a{
    font-size: 17px;
   color:#5a429b; 
   font-weight:600;padding-bottom: 8px;
   border-bottom:2px solid #fff;
}
.my_profile_tab{flex-wrap: initial;}
.my_profile_tab li{      margin-right: 3%;}
.my_profile_tab li a.active{ color:#ED4E94;border-bottom:2px solid #ED4E94;}
.646464{color: #646464}
.dashbord_right{    width: 100%;}
.inr_a {
    position: absolute;
    top: 12%;
    height: 75%;
    line-height: 30px;
    width: 35px;
    left: 10px;
    padding-right: 8px;
    border-right: 1px solid #ccc;
}
.position_relative_inr{ position:relative}
.position_relative_inr .form-control{  padding-left:50px}
.add_images{position:relative; color:#ED4E94; font-weight:600; display:flex;width: 150px; align-items: center;}
.add_images i{ border: 2px solid #ED4E94; border-radius:100%; width:30px; height:30px; line-height:26px; text-align:center; margin-left:10px}
.add_images input{cursor:pointer;  opacity:0; position:absolute; left:0; top:0; width:100%;height:100%}

.add_pravu_item {
    border: 1px solid #b0b0b0;
    border-radius: 10px;
    padding: 10px;
    position: relative;
    margin-right: 1px; margin-bottom:15px
   
}
.add_pravu_footer { font-size: 13px;}
.add_pravu_img{ border-radius:8px; overflow:hidden; margin-bottom:10px; 
                 background: #eae9ed;}
.add_pravu_img img{    width: auto;    margin: auto;
    display: block;
    height: 130px;}
.add_delite {
    background-color: #5A429B;
    width: 25px;
    height: 25px;
    border-radius: 100%;
    color:#fff; text-align:center;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
}
.xd_btn {
    border: 1px solid #5e479d;
    outline: none;
    color: #4b4b4b;
    font-size: 20px;
    padding: 3px 10px;
    border-radius: 5px; background:#fff;
}
.services_li li {
    max-width: 16.666667%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    font-size: 14px;
    margin-bottom: 10px;
    display: block;
}

.upload_btn_p {
    padding: 5px 12px;
    border-radius: 5px;
    border: 2px solid #ed4e94;
    background: #fff;
    color: #ed4e94;
    font-weight: 600;
    display: inline-block;
}
.upload_btn_p:hover{background:#ed4e94; color:#fff}
.date_status i{    font-size: 34px;
    line-height: 45px;color: #5a429b;
    margin-right: 10px
;}
.social_info img{ width:40px ;height:40px; margin-right:10px}
.social_info h5{ color:#797979; padding-bottom:0}
.social_info a{ color:#797979}
.social_info{ margin-bottom:30px}
.services_provider_img img {
    margin: auto;
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 100%;
}
.services_provider_img {
    text-align: center;
    display: block;
    padding: 10px 0 20px;
}
.cancial_btn_brand{ color:#c4ccce; font-size:30px; border:none; background:none; margin-top:10px;}
.brand_items{ margin-bottom:30px;color: #424242;}
.brand_items .brand_value{ margin-top:10px; display:block;    font-weight: 600;}
.cancial_btn_brand:hover{color: #ed4e94;}
.team_user{
    object-fit: cover;
    border-radius: 100%;
    height: 100px;
    width: 100px; 
}
.team_box{ text-align:center;  border-radius: 8px; padding:15px;font-size: 14px;    height: 100%;}
.team_box h5{font-size: 17px; margin-top:10px }
.team_post{opacity:0.8;}
.team_admin{font-size: 15px;    display: block; margin-top:5px; font-weight:600}
.team_contact_d {
    padding: 5px 5px 0;
    margin-top: 8px; text-align:left;
    border-top: 1px solid #efeeee;
}
.team_contact_d i{ margin-right:8px; color:#ef609f}
.role_items .brand_value{ color:#ef609f; display:block; margin-top:15px}
.role_items button{
    font-size: 20px;
    border: none;
    background: none;color:#ef609f;
}
.team_contact_d span{  display:block;}
.role_items_man{
	-webkit-box-shadow: 0px 2px 4px rgb(0 0 0 / 20%);
    box-shadow: 0px 2px 4px rgb(0 0 0 / 20%);
        border-radius: 10px;
    padding:10px 15px; margin-bottom:25px
	}
.solution_list_box p{    font-size: 14px;}
.solution_list_box a:hover{ color:#222;}
.solution_list_box {
    -webkit-box-shadow: 0px 2px 4px rgb(0 0 0 / 20%);
    box-shadow: 0px 1px 4px rgb(0 0 0 / 30%);
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 25px;font-size: 14px;
}
.solution_l_right{ text-align:right}
.skills span{ margin:10px 10px 0 0}
.solution_b_bottom span{ margin:10px 10px 0 0}
.solution_b_bottom{ margin:10px 20px 0 0}
.solution_details_tab {
    background: #ECECEC;
    border-radius: 25px;
    overflow: hidden;
    display: inline-flex; 
}
.solution_details_tab li a {
    padding: 12px;
    font-weight: 600;
    min-width: 130px;
    text-align: center;
}
.solution_details_tab li a.active{ background: #1776bb; color:#fff;}

.solution_dital_page .input-group-text,
.solution_dital_page .form-control
{    border: 1px solid #707070;    height: 45px;}
.solution_dital_page textarea.form-control{border: 1px solid #707070;    height: 145px;}

.solution_dital_stake {
    border-radius: 0 25px 25px 0;
    padding: 8px 45px 8px 24px;
    color: #fff;
    margin-bottom: 15px;
    display: inline-block; width: 150px;
}
.proposals_user{-webkit-box-shadow: 0px 2px 4px rgb(0 0 0 / 20%);
    box-shadow: 0px 2px 4px rgb(0 0 0 / 20%);
        border-radius: 10px;
    padding:10px 15px; margin-bottom:25px}
.member_crd .card-header{ background:#fff;height: 55px;}
.member_crd h5{ display:flex;    align-items: center;     }
.lh16{ line-height: 16px;}
.member_crd h5 img{     width: 20px;    margin-right: 10px;}
.member_crd .btn{font-size:14px; color:#fff; padding:4px;    height: 36px;    min-width: 100px;}
.member_crd .btn small{font-size:9px; }
.member_crd .btn_bb{color:	#222222; padding:4px; border:1px solid #ED4E94;    line-height: 13px;}

.member_crd .btn_m1{background:#8EE46A;}
.member_crd .bg_man{background:#dd4588;}
.member_list li{ position:relative; display:block; margin-top:10px; padding-left:18px
; font-size:13px}
.member_list{ margin-top:20px}
.member_list li:after{ content: "\f00c";
    font: normal normal normal 14px/1 FontAwesome; color:#8EE46A;
	position:absolute; 
	left:0; top:2px
}
.btn_border {
    border: 1px solid #5e5e5e;
    color: #565656;
    padding: 6px 20px;
    margin-top: 10px;
}

@media (min-width: 992px){
.container, .container-lg, .container-md, .container-sm {
    max-width: 95%;
	
}

.header__toggle {
    display: none;
}
}
@media (min-width: 768px){
.container, .container-md, .container-sm {
    max-width: 96%;
}}
@media (min-width: 576px){
.container, .container-sm {
    max-width: 100%;
}}
@media only screen and (min-width: 1200px) {
	.container {
		max-width: 1640px;    padding: 0 50px;
	}
	
}

@media only screen and (max-width: 1639px) {
	.custom-card p {
		font-size: 14px;
	}
	.extraordinary-card { margin-bottom:60px; }
	.navbar .navbar-nav li a.nav-link {
		font-size: 18px;
	}
	.item-body {
		padding-left: 0;
		padding-right: 0;
	}
	.newsletter-box .form-control {
		max-width: 500px;
	}
	.newsletter-box .btn-primary {
		min-width: 210px;
	}
}

@media only screen and (max-width: 1547px) {
	.solution-card {
	padding-bottom: 35px;
}

	.item-style-two h4 {
		min-height: auto;
		width: 68%;
	}
	.newsletter-box .form-control {
		max-width: 475px;
	}
.banenr-section img.w-100 {max-height: 450px;    object-fit: cover;}
.product_banenr{min-height: 450px;}
.in_banenr img{min-height: 450px;object-fit: cover;}
.convetional_banenr img{min-height: 500px;object-fit: cover;}
}

@media only screen and (max-width: 1499px) {
	.newsletter-box .form-control {
		max-width: 424px;
	}
	.product_service_box .icon_img {
    height: 60px;
}
.menu_title { font-size: 14px;}
.extraordinary-card { margin-bottom:40px; }
	.product_service_box h4{    font-size: 1.2rem;}
	.sluton-header h4 {   font-size: 20px;}
	.newsletter-box .btn-primary {
		min-width: 170px;
		margin-left: 15px;
	}
	.custom-card p br {
		display: none;
	}
.social-links li a {
    width: 45px;
height: 45px;}

	.badge-left {
		width: 80%;
	}
	.badge-left span, .rating-box {
		font-size: 16px;
	}
	.rating-box img {
		max-width: 18px;
	}
.shop-item .item-img {
    height: 100px;display: block;
}
	.pt-65 {
    padding-top: 50px;
	}
	.pb-65 {
	   padding-bottom: 50px;
	}
	.middle-section {
	padding: 25px 0 40px;
}
.card-left {
    width: 20%;  
}
.custom-card h2 {
    font-size: 20px;
}
.card-right {width: 66%;    padding-bottom: 10px;}
.card-right img{width: 25px;}
.heading-title h2 { font-size: 40px;}
.heading-title p {    font-size: 20px;}
}

@media only screen and (max-width: 1299px) {
.specifications_list li{ -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;}
	.custom-card p {
		font-size: 14px;
	}
	.card-right {
		padding-left: 10px;
	}
	.shop-btn img {
		max-width: 24px;
	}
	.shop-item h4 {
		min-height: 60px;
	}
	.item-img {
		min-height: 158px;
	}
	.solution-card {
		padding-right: 20px;
	}
	.sluton-header h4 {
		font-size: 20px;
	}
	.feature-list h3 {
		font-size: 24px;
		padding-bottom: 10px;
	}
	.feature-list p {
		font-size: 16px;
		line-height: 1.5;
	}
	.download-appbox p {
		font-size: 20px;
	}
	.newsletter-box .form-control {
		max-width: 375px;
		height: 60px;
	}
	.newsletter-box .btn-primary {
		height: 60px;
		font-size: 20px;
	}
	.newsletter-section p {
		line-height: 24px;
		padding-top: 10px;
	}
	.social-links li {
		padding: 5px;
	}
	.copyright:not(.payment-method) img {
		margin-right: 15px;
		max-width: 105px;
	}
	.social-links li a img{    height: 56%;}
	.social-links li a {
		width: 35px;
		height: 35px;
	}
	.payment-method img {
		margin: 0;
	}
	.social-title {
		font-size: 20px;
	}
	.navbar-brand {
		max-width: 60px;
	}
	.navbar .navbar-nav li:nth-last-child(2) {
		padding-left: 20px;
	}
	
}

@media only screen and (max-width: 1199px) {
	.navbar .navbar-nav li a.nav-link {
		font-size: 14px;
		padding: 4px 10px;
	}
.services_li li {max-width: 20%;}	
.specifications_list li{max-width: 25%;-ms-flex: 0 0 25%;
    flex: 0 0 25%;}	
.product_service_box h4{    font-size: 1.2rem;}
	.post_a_job h5, .post_a_job2 h5{font-size: 16px;}
	.navbar .navbar-nav li:last-child {
		padding-left: 0px;
	}
	.navbar .search-box {
		width: 34%;
	}
	.search-box select {
    width: 120px;
	}
	.navbar .navbar-nav li:nth-last-child(2) {
		padding-left: 0;
	}
	
	.custom-card p {
		padding-bottom: 8px;
	}
	.custom-card h2 {
		font-size: 18px;
	}
	.middle-section {
		padding: 40px 0;
	}
	.pt-100 {
		padding-top: 50px;
	}
	.pt-100.pb-100 {
		padding-bottom: 0;
	}
	.heading-title h2 {
		font-size: 36px;
		line-height: 1.3;
	}
	.heading-title p {
		font-size: 20px;
	}
	.pt-90 {
		padding-top: 40px;
	}
	
	.shop-heading h3 {
		font-size: 21px;
	}
	.pt-xl-4.pb-100 {
		padding-bottom: 10px;
	}
	.solution-card {
		padding-right: 0;
		padding-bottom: 20px;
	}
	.sluton-header h4 {
		font-size: 18px;
	}
	.feature-list {
		padding: 0 0 20px;
	}
	.feature-list h3 {
		font-size: 20px;
	}
	.download-appbox h2 {
		font-size: 36px;
	}
	.download-appbox h5 {
		font-size: 24px;
	}
	.download-appbox p {
		font-size: 16px;
		line-height: 36px;
	}
	.newsletter-box .form-control {
		max-width: 300px;
	}
	.newsletter-box .btn-primary {
		min-width: auto;
    margin-left: 10px;
    padding: 10px 15px;
	}
	.pt-100.pb-90 {
		padding-bottom: 50px;
	}
	.payment-method img {
		margin-left: 15px;
	}
	.footer-bottom {
		margin-top: 40px;
	}
	.footer-bottom .social-links {
		padding-bottom: 20px;
	}
	footer {
		padding-top: 50px;
	}
	.footer-menu li a {
		
		line-height: 1.6;
	}
	.footer-menutitle {
		font-size: 21px;
	}
	.banenr-section h1{
		font-size: 35px;
		line-height: 1.1;
	}
	
}
@media only screen and (max-width: 991px) {
.header-top li {padding-left: 20px;	}
.services_li li {max-width: 25%;}
.dashbord_welcome h2 {  font-size: 24px;}
.dashbord_in { padding: 30px 0;}	
.solution_l_right {  text-align: left; margin-top:20px}
.dashbord_in.dashbord_in_man {
    padding: 15px;
}
.role_items{ display:flex;width: 100%;}
.role_items .brand_value {
    margin: 0 0 15px auto; padding-left:10px;
    display: block;
    text-align: right;
}
.nav-scroll .filter{ display:none}	
.menu2{overflow:scroll;padding: 15px 0 0;}	
.menu2 ul li { margin-right: 20px;}
.menu2 ul li a{    width: max-content;padding-bottom: 10px;}
.space_dashbord {height: 100px;}
.w125 { width: auto;}
.more_filter .tab-content {position: initial;    width: 100%;}
.drop_collapse {position: initial;    width: 100%; margin-top:5px }
.more_tab {
    width: 100%;
    display: flex;
    overflow-y: auto;
    flex-wrap: initial;
}
.more_tab li a.active:before{ display:none}
.more_tab li a.active{border-bottom:2px solid #ed4e94}
.more_tab li a {
    padding: 6px 10px 10px;
    width: max-content;
}
#MoreFilter {
    width: 100%;
}
#top_menu {    padding: 10px 0;}
.row-flex{ display:block}
.filter_box {
    border-left: 0px solid #ccc;
	  border-bottom: 1px solid #ccc;
}
.row-flex .filter_box:last-child {
    border-right: 0px solid #ccc;
}
.space_header {
    height: 120px;
}
.lcp_icon{ margin-top:20px}
.header2 .navbar .search-box{ display:none}
	.pb-50 {
    padding-bottom: 25px;
}
.product_header {
    padding: 0!important;
}
.filter {    
    margin-top: 0;
	display: none;
}
.filter-pin-c{
	display: block !important;
}
.product-open-icon{
	display: none;
}
.bottom-search .filter {    
	display: block;
}
.specifications_list li{max-width: 33%;-ms-flex: 0 0 33%;
    flex: 0 0 33%;}
.nav-link.text-black{color: #fff!important;
    padding:6px 0!important;    text-align: left;
}
.join_us_page h2 {    font-size: 30px;}
.join_us_right {    padding: 25px 20px;    border-radius: 15px;}
.were_world .tab-content ul li {width: 33%;}
.p80 {
    padding: 30px 0;
}
h2, .h2 {font-size: 30px;}


.pt-65 {
    padding-top: 35px;
}
.pb-65 {
   padding-bottom: 35px;
}
	.header-top .cart sup {
		right: -10px;
	}
	.heading-title h2 {
		font-size: 30px;
	}
	.heading-title p {
		font-size: 18px;
		line-height: 1.1;
	}
	.download-appbox p {
		line-height: 1.4;
	}
	.download-appbox p br {
		display: none;
	}
	.download-appbox {
		padding-bottom: 30px;
	}
	.newsletter-box {
		margin-top: 30px;
	}
	.newsletter-box .form-control {
		max-width: calc(100% - 150px);
	}
	.carousel-item img {
		min-height: 450px;
		object-fit: cover;
	}
	.navbar-brand img {
		max-width: 50px;
	}

	.navbar .navbar-collapse {
		background-color: #5A429B;
		padding: 20px;    border-radius: 15px;
	}

	.navbar .navbar-nav li a.join-btn{width: 70px;
    padding: 7px 10px;
    text-align: center;}
	.navbar .navbar-nav li a.nav-link {
		padding: 8px 0;
	}
	
.nav-scroll .navbar .search-box{
		width: 40%;
		margin-right: 90px;margin-top: 0;
	}
	.nav-scroll .navbar-toggler {
		top: 2px;
	}
	.nav-scroll .navbar .navbar-nav li a.nav-link:not(:hover) {
		color: #fff;
		border-color: #fff;
	}
.growing_box h3 {    font-size: 30px;}
.info_box li {  max-width: 50%;}
}
@media only screen and (max-width: 767px) {
.rebuild_ebsite {    padding: 6px 15px;}
.member_crd .card-body { padding: 0.75rem 0.6rem;}
.member_crd{    height: auto;}
.member_crd .card-header {
    height: auto;
    padding: 0.75rem 0.6rem;
}
.services_li li {max-width: 50%;font-size: 12px;}
.my_profile_tab{  overflow-y:scroll}
.my_profile_tab li a{width: max-content;    display: block; margin-right:20px}
.re_tc { text-align:center}
.header2 .navbar .search-box{ display:none}
.scrool_tab {     overflow-y: scroll;    display: flex;}
.scrool_tab li a{width: max-content;}
.delivery_box {
    display: block;
    padding: 15px;
}

.banenr-section h1 small {font-size: 24px;	}
.form-group.df, .post_a_service_box{ display:block}
.post_a_service_box .icon_img { display: block; margin-bottom: 20px;}
.in_banenr img {min-height: 310px;}
.navbar2 {    margin-top: 20px;}
#SignIn{ font-size:14px}
.nav-scroll .navbar2 {    margin-top: -10px;}
.nav-scroll .navbar-light .navbar-toggler {top: -25px;}
.product_header.nav-scroll .navbar-light .navbar-toggler {top: -30px;}
.banner_content p {
    font-size: 15px;
    line-height: 20px;
}
.banner_content h1 {
    font-size: 25px;
}
.upload_btn {width: 200px; margin-bottom:10px}
.convetional_banenr .btn { min-width: 110px; margin: 0 10px;}
	.growing_box { text-align: center;    padding-top: 25px;}
	.growing_box h3 {    font-size: 24px;}
	.were_world .tab-content ul li {width: 50%;}
	h2, .h2 {font-size: 24px;
    line-height: 36px;
}
.a-business-section h2{    margin-top: 20px;}
	.navbar-light .navbar-toggler{    width: 30px;    height: 30px;top: -30px;
    right: -5px;}
	.h3, h3 {    font-size: 1.4rem;}
	.banenr-section h1 {
		font-size: 24px;
	}
	.nav-scroll .navbar .search-box {
    display: none;
}
	.search-box {
		width: 100%;
	}
	.banenr-section .carousel-caption {
		left: 0;
		right: 0;
	}
	.heading-title p {
		font-size: 16px;
	}
	.shop-item h4 {
		min-height: auto;
	}
	.heading-title h2 {
		font-size: 24px;
	}
	.btn-link {
		font-size: 16px;
	}
	.shop-heading h3 {
		font-size: 18px;
	}
	.feature-list h3 {
		line-height: 1.3;
	}
	.download-appbox h2 {
		font-size: 24px;
		line-height: 1.2;
	}
	.download-appbox h5 {
		font-size: 18px;
	}
	.footer-bottom [class*="col"] {
		width: 100%;
		text-align: center;
		margin-bottom: 20px;
	}
	.footer-bottom [class*="col"]:last-child {
		margin-bottom: 0;
	}
	.footer-bottom .social-links {
		justify-content: center;
	}
	.items-carousel .owl-nav {
		right: 65px;
		top: -32px;
	}
	.payment-method img {
    margin-left: 0;
}
	.navbar .search-box {
		margin-right: 55px;
	}
	.price {
		font-size: 16px;
	}
	.badge-left span, .rating-box {
		font-size: 14px;
	}
	.banenr-section h1 small {
		font-size: 20px;
		line-height: 1.3;
	}
	.nav-scroll .navbar-brand {
    max-width: 50px;
}
.navbar-brand {
   margin: -45px 10px 0 0;
    padding: 15px 6px 10px;
}
.nav-scroll .navbar-brand {
    margin: -30px 15px 8px 0px;
}.header-top{    padding-right: 40px;
    margin-bottom: 0;
    width: 100%;}
	.product_service_box {
    display: block;
    text-align: center;}
.product_service_box .icon_img {
    height: 50px;
    margin-bottom: 20px;
    display: block;
}
.std .product_service_box {margin-bottom: 20px;}

}
@media only screen and (max-width: 575px) {
	.specifications_list li{max-width: 50%;-ms-flex: 0 0 50%;
    flex: 0 0 50%;}
.about_seller, .star_revi  {
    display: block;
}
.info_box li {  max-width: 100%;     margin-bottom: 15px;}
.lSPager li a {
    border-radius: 10px;
}
.about_seller { text-align:center}
	.header-top li a:not(.btn) .mr-2 {
		margin-right: 4px !important;
	}
	.header-top li a:not(.btn) {
		font-size: 10px;
	}
	.header-top li:last-child .btn-sm {
		padding-left: 5px;
	}
	.navbar-toggler {
		width: 40px;
		height: 40px;
	}
	.header-top li {
		padding-left: 10px;
	}
	
	.search-input{
		width: 60%;
	}
	
.make-body { font-size: 12px;}	
.make-body span{ font-size: 10px;}	
.make_it_real {    border-radius: 10px
}

.header-top .cart sup {
    width: 22px;
    height: 22px;
    font-size: 12px;
    top: -10px;
}
	.navbar-light .navbar-toggler .navbar-toggler-icon {
    display: inline-block;
    width: 1.2em;}
	.search-btn {
		right: 5px;
	}
	.navbar-brand {
		margin-right: 0;
	}

	.nav-scroll .navbar .search-box {
		display: none;
	}
	.nav-scroll .navbar-brand {
		max-width: 60px;
	}
}
@media only screen and (max-width: 479px) {
	.search-box select {
		width: 100%;
		padding: 13px 50px 13px 15px;
	}
	.card-left {
    width: 25%;
}
.card-right {
    width: 65%;
}
	.search-input input {
		height: 46px;
		width: 100%;
		padding-right: 25px;
	}
	.search-input {
		position: relative;
		width: 100%;
	}
	.nav-scroll .navbar-toggler {
		
	}
	.shop-heading {
		flex-direction: column;
		align-items: flex-start;
	}
	.items-carousel .owl-nav {
		right: auto;
		left: 0;
	}
	.shop-heading h3 {
		padding-bottom: 5px;
	}
	.item-style-two h4 {
		width: 75%;
	}
	.app-btn a {
		margin-right: 0;
	}
	.app-btn a + a {
		margin-top: 10px;
		display: inline-block;
	}
	.newsletter-section h3 {
		line-height: 1.4;
	}
	.newsletter-box .form-control {
		max-width: 100%;
	}
	.newsletter-box .btn-primary {
		margin-left: 0;
		width: 100%;
		margin-top: 10px;
		height: 50px;
	}
	.newsletter-section {
		padding: 40px 0;
	}
	.footer-menutitle {
		font-size: 19px;
	}
	.copyright, .social-title {
		font-size: 16px;
	}
	.copyright:not(.payment-method) img {
		max-width: 80px;
	}
	.footer-menu li a {
		font-size: 15px;
	}

	.header-top li:last-child {
		display: none;
	}
	header, .nav-scroll{
		padding: 14px 0 5px!important;
	}
	.cart img {
		max-width: 24px;
		margin-right: 8px;
	}
}

.navbar-nav li{
	position: relative;
}
.navbar-nav li .sub-menu {
	position: absolute;
	list-style: none;
	max-height: 80vh;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
	left: -50%;
	min-width: 230px;top: 125%;
	padding: 0px 0px;
	visibility: hidden;
	opacity: 0;
	margin-top: 35px;
	background: #fff;
	z-index: 99;
	-webkit-transition: all 0.4s;
	-o-transition: all 0.4s;
	transition: all 0.4s;
	-webkit-box-shadow: 2px 7px 20px rgba(0, 0, 0, 0.05);
	box-shadow: 2px 7px 20px rgba(0, 0, 0, 0.05);
	border-radius: 10px !important;
	color: #333;
}

.navbar-nav li:hover .sub-menu {
	visibility: visible;
	opacity: 1;
	margin-top: 0;
}

.navbar-nav li .sub-menu > li a{
	color: #333;
display: block;padding: 10px 20px!important;}
.sub-menu li a{
	padding: 10px 20px;
	display: inline-block
}
.sub-menu li a:hover{
	color: #ED4E94 !important;}
.sub-menu li:last-child{
	padding-left: 0px !important
}
.sub-menu li a {
    color:rgba(0,0,0,.5) !important;
}


.bg-lightt {
    background-color: #ffffff !important;
    border: 0px solid #434343;
    margin-top: 30px;
    border-radius: 0px;
}

.nav-item.dropdown {
    padding: 8px 2px;
}

#navbarDropdownMenuLink  .fa {
    font-size: 17px;
    color: #8a8a8a;
}

#navbarDropdownMenuLink {
    font-size: 14px;
    letter-spacing: 0.5px;
    color: #2e2e2e;
}

#navbarDropdownMenuLink:hover {
    color: #ed4e94;
}


.dropdown-menu {
    min-width: 200px;
    right: 0;
    /* left: 0; */
    box-shadow: 0 9px 19px 7px rgba(0, 0, 0, .07);
    border: none;
    border-radius: 8px;
    padding: 0;
    transition: all ease .6s;
    display: none;
}


.dropdown-menu a.dropdown-item {
	padding: 5px 20px;
	font-size: 16px;
	font-weight: 400;
	position: relative
}

.dropdown-item:focus, .dropdown-item:hover {
    color: #ed4e94;
    text-decoration: none;
    background-color: #e9ecef;
}

.dropdown-menu a.dropdown-item img {
	margin-right: 10px;
	width: 20px
}
.image-dd img {
    height: 30px;
    width: 30px;
    border-radius: 50%;
}

@media (min-width: 768px) { 
	.dropdown:hover .dropdown-menu {
		display: block;
		margin-top: 0; 
	 }
	}
	@media (max-width: 767px) { 
	.dropdown:hover .dropdown-menu{
		display: none;
		margin-top: auto; 
	}
}
img.mx-auto.item-img {
    height: 200px;
    width: 100%;
}
/* ---------------------------------------------------------- */
.details-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: minmax(30px, auto);
    grid-gap: 10px;
    padding: 5px 0 0 0;
}
.tracking-wrapper,.details-grid-content {
    background: white;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 3px 5px -1px #0003, 0 6px 10px #00000024, 0 1px 18px #0000001f;
}
.details-list-wrapper ul {
    padding: 0;
    text-align: left;
    list-style: none;
    margin-bottom: 0;
}

label.title-label {
font-weight: 600;
font-size: 14px;
}
ul.details-flex-column li {
    display: grid;
    grid-template-columns: 2fr 10fr;
	grid-gap: 2px;
}
.tracking-wrapper {
    margin-top: 10px;
}
ol.progtrckr {
    /* margin: 0; */
    padding: 0;
    list-style-type: none;
}

ol.progtrckr li {
    display: inline-block;
    text-align: center;
    line-height: 3.5em;
}

ol.progtrckr[data-progtrckr-steps="2"] li { width: 49%; }
ol.progtrckr[data-progtrckr-steps="3"] li { width: 33%; }
ol.progtrckr[data-progtrckr-steps="4"] li { width: 24%; }
ol.progtrckr[data-progtrckr-steps="5"] li { width: 19%; }
ol.progtrckr[data-progtrckr-steps="6"] li { width: 16%; }
ol.progtrckr[data-progtrckr-steps="7"] li { width: 14%; }
ol.progtrckr[data-progtrckr-steps="8"] li { width: 12%; }
ol.progtrckr[data-progtrckr-steps="9"] li { width: 11%; }

ol.progtrckr li.progtrckr-done {
    color: black;
    border-bottom: 4px solid yellowgreen;
}
ol.progtrckr li.progtrckr-todo {
    color: silver; 
    border-bottom: 4px solid silver;
}

ol.progtrckr li:after {
    content: "\00a0\00a0";
}
ol.progtrckr li:before {
    position: relative;
    bottom: -2.5em;
    float: left;
    left: 50%;
    line-height: 1em;
}
ol.progtrckr li.progtrckr-done:before {
    content: "\2713";
    color: white;
    background-color: yellowgreen;
    height: 2.2em;
    width: 2.2em;
    line-height: 2.2em;
    border: none;
    border-radius: 2.2em;
}
ol.progtrckr li.progtrckr-todo:before {
    content: "\039F";
    color: silver;
    background-color: white;
    font-size: 2.2em;
    bottom: -1.2em;
}