.searching-dropdown nav {    
    display: block;
    /* text-align: center; */
  }
.searching-dropdown nav ul {
    margin: 0;
    padding:0;
    list-style: none;
  }
.searching-dropdown .nav a {
    display: block;
    /* background: #111; */
    color: #000;
    text-decoration: none;
    padding: .8em 1.8em;
    text-transform: uppercase;
    /* font-size: 80%; */
    /* letter-spacing: 2px; */
    /* text-shadow: 0 -1px 0 #000; */
    position: relative;
    font-size: 16px;
    font-weight: 500;
  }
.searching-dropdown .nav{  
    vertical-align: top; 
    display: inline-block;
    /* box-shadow: 1px -1px -1px 1px #000, -1px 1px -1px 1px #fff, 0 0 6px 3px #fff; */
    border-radius:6px;
    width: 100%;
  }
 .searching-dropdown .nav li{position: relative;}
 .searching-dropdown .nav > li { 
    float:left; 
    border-right: 1px #aaa solid; 
    margin-right: 1px; 
  } 
  .searching-dropdown .nav > li > a { 
    /* margin-bottom:1px; */
    /* box-shadow:inset 0 2em .33em -.5em #555;  */
  }
 .searching-dropdown .nav > li:hover , .nav > li:hover >a{  border-bottom-color:orange;}
.searching-dropdown  .nav li:hover > a { color:orange; }
.searching-dropdown  .nav > li:first-child  { 
  /* border-radius: 4px 0 0 4px; */
  border-left: 1px #aaa solid;
} 
.searching-dropdown  .nav > li:first-child>a{border-radius: 4px 0 0 0;}
.searching-dropdown  .nav > li:last-child  { 
    border-radius: 0 0 4px 0; 
    margin-right: 0;
    float: right;
    border: none;
  } 
 .searching-dropdown .nav > li:last-child >a{border-radius: 0 4px 0 0; }
.searching-dropdown  .nav li li a { margin-top:1px}
  
  
  
   .searching-dropdown .nav li a:first-child:nth-last-child(2):before { 
     content:""; 
     position: absolute; 
     height:0; 
     width: 0; 
     border: 5px solid transparent; 
     top: 50% ;
     right:5px;  
   }
   
   
   
   
   
   /* submenu positioning*/
 .searching-dropdown .nav ul {
    position: absolute;
    white-space: nowrap;
    /* border: 2px solid #4056a8; */
    /* border-radius: 0px 0px 12px 8px; */
    z-index: 1;
  left: -99999em;
  }
 .searching-dropdown .nav > li:hover > ul {
  left: auto;
  padding-top: 5px  ;
  min-width: 100%;
  background: #e1e6ea;
  }
 .searching-dropdown .nav > li li ul {  border-left: 1px solid #fff;
    background: #e2e5ea;}
  
  
 .searching-dropdown .nav > li li:hover > ul { 
  /* margin-left: 1px */
  left: 100%;
  top: -1px;
  }
  /* arrow hover styling */
 .searching-dropdown .nav > li > a:first-child:nth-last-child(2):before { 
  border-top-color: #aaa; 
  }
 .searching-dropdown .nav > li:hover > a:first-child:nth-last-child(2):before {
  border: 5px solid transparent; 
  border-bottom-color: orange; 
  margin-top:-5px
  }
 .searching-dropdown .nav li li > a:first-child:nth-last-child(2):before {  
  border-left-color: #aaa; 
  margin-top: -5px
  }
 .nav li li:hover > a:first-child:nth-last-child(2):before {
  border: 5px solid transparent; 
  border-right-color: orange;
  right: 10px; 
  }
  
  .searching-dropdown .drop_collapse{
    position: relative !important;
  }
  .searching-dropdown .nav a.no-pad{
    padding: 0;
  }
  /* ------------------ Vertical v-Navigation--------------------- */
  .v-navigation {
    padding: 0;
    margin: 0;
    border: 0;
    line-height: 1;
  }
  .v-navigation ul,
  .v-navigation ul li,
  .v-navigation ul ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .v-navigation ul {
    position: relative;
    z-index: 500;
    float: left;
  }
  .v-navigation ul li {
    float: left;
    min-height: 0.05em;
    line-height: 1em;
    vertical-align: middle;
    position: relative;
  }
  .v-navigation ul li.hover,
  .v-navigation ul li:hover {
    position: relative;
    z-index: 510;
    cursor: default;
  }
  .v-navigation ul ul {
    visibility: hidden;
    position: absolute;
    top: 100%;
    left: 0px;
    z-index: 520;
    width: 100%;
  }
  .v-navigation ul ul li { float: none; }
  .v-navigation ul ul ul {
    top: 0;
    right: 0;
  }
  .v-navigation ul li:hover > ul { visibility: visible; }
  .v-navigation ul ul {
    top: 0;
    left: 99%;
  }
  .v-navigation ul li { float: none; }
  .v-navigation ul ul { margin-top: 0.05em; }
  .v-navigation {
    width: max-content;
    background: #f0f0f042;
    font-family: 'roboto', Tahoma, Arial, sans-serif;
    zoom: 1;
  }
  .v-navigation:before {
    content: '';
    display: block;
  }
  .v-navigation:after {
    content: '';
    display: table;
    clear: both;
  }
  .v-navigation a {
    display: block;
    padding: 1em 1.3em;
    color: #ffffff;
    text-decoration: none;
    text-transform: uppercase;
  }
  .v-navigation > ul { width: max-content; }
  .v-navigation ul ul { width: max-content; }
  .v-navigation > ul > li > a {
    border-right: 0.2em solid #e9ecef;
    color:#343a40;
  }
  .v-navigation > ul > li > a:hover { color: #ffffff; }
  .v-navigation > ul > li a:hover,
  .v-navigation > ul > li:hover a { background: #aeaeae; }
  .v-navigation li { position: relative; }
  .v-navigation ul li.has-sub > a:after {
    content: '»';
    position: absolute;
    right: 1em;
  }
  .v-navigation ul ul li.first {
    -webkit-border-radius: 0 3px 0 0;
    -moz-border-radius: 0 3px 0 0;
    border-radius: 0 3px 0 0;
  }
  .v-navigation ul ul li.last {
    -webkit-border-radius: 0 0 3px 0;
    -moz-border-radius: 0 0 3px 0;
    border-radius: 0 0 3px 0;
    border-bottom: 0;
  }
  .v-navigation ul ul {
    -webkit-border-radius: 0 3px 3px 0;
    -moz-border-radius: 0 3px 3px 0;
    border-radius: 0 3px 3px 0;
  }
  .v-navigation ul ul { border: 1px solid #aeaeae; }
  .v-navigation ul ul a { color: #ffffff; }
  .v-navigation ul ul a:hover { color: #ffffff; }
  .v-navigation ul ul li { border-bottom: 1px solid #aeaeae; }
  .v-navigation ul ul li:hover > a {
    background: #aeaeae;
    color: #ffffff;
  }
  .v-navigation.align-right > ul > li > a {
    border-left: 0.3em solid #aeaeae;
    border-right: none;
  }
  .v-navigation.align-right { float: right; }
  .v-navigation.align-right li { text-align: right; }
  .v-navigation.align-right ul li.has-sub > a:before {
    content: '+';
    position: absolute;
    top: 50%;
    left: 15px;
    margin-top: -6px;
  }
  .v-navigation.align-right ul li.has-sub > a:after { content: none; }
  .v-navigation.align-right ul ul {
    visibility: hidden;
    position: absolute;
    top: 0;
    left: -100%;
    z-index: 598;
    width: 100%;
  }
  .v-navigation.align-right ul ul li.first {
    -webkit-border-radius: 3px 0 0 0;
    -moz-border-radius: 3px 0 0 0;
    border-radius: 3px 0 0 0;
  }
  .v-navigation.align-right ul ul li.last {
    -webkit-border-radius: 0 0 0 3px;
    -moz-border-radius: 0 0 0 3px;
    border-radius: 0 0 0 3px;
  }
  .v-navigation.align-right ul ul {
    -webkit-border-radius: 3px 0 0 3px;
    -moz-border-radius: 3px 0 0 3px;
    border-radius: 3px 0 0 3px;
  }
.has-sub img {
  height: 2em;
  width: 3em;
}

a.has-sub-content {
    font-size: 13px;
    text-align: justify;
}
/* ------------------------- */
.filter-pin-c{
  display: none;
}
.filter-pin-tip{
  display: grid;
  grid-template-columns: 11fr 1fr;
  grid-gap: 10px;
}
.filter-pin{
  position: fixed;
  z-index: 9999;
  width: 60px;
  background: #4254a6;
  font-size: 25px;
  padding: 0px 10px 0px 10px;
  /* margin-top: 150px; */
  transition: all 0.3s linear;
  box-shadow: 2px 2px 4px 0px rgb(0 0 0 / 40%);
}
#snackbar {
  visibility: hidden;
  /* min-width: 250px; */
  /* margin-left: -125px; */
  /* background-color: #e9ecef; */
  /* color: #fff; */
  /* text-align: center; */
  /* border-radius: 2px; */
  /* padding: 5px; */
  position: fixed;
  z-index: 99999;
  /* left: 50%; */
  bottom: 0px;
  /* font-size: 17px; */
  width: 100%;
  max-height: 100vh;
  min-height: 55vh;
  -webkit-animation: fadeout 0.5s;
  animation: fadeout 0.5s;
}

#snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s;
  animation: fadein 0.5s;
}
/* , fadeout 0.5s 2.5s */
/* 
@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;} 
  to {bottom: 30px; opacity: 1;}
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {bottom: 30px; opacity: 1;} 
  to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
} */

/* -------------------- */
.bottom-search{
  background: white;
    border: 10px solid #c1c1c1;
}
.search-box-0101 {
  display: grid;
  grid-gap: 5px;
  grid-template-columns: 3fr 7fr;
  height: 55vh;
}
.left-0101 .nav-tabs .nav-link.active {
  border: none;
  text-align: end;
  color: #e65794;
  font-weight: 600;
}
.left-0101 .nav-tabs .nav-link {
  border: none;
  text-align: end;
}
.left-0101 {
  background: #e9ecef;
}
.pickup-pro .form-check-label {
  margin-left: 25px;
}